import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  ISO8601Date: { input: any; output: any; }
  ISO8601DateTime: { input: any; output: any; }
  SimpleDate: { input: any; output: any; }
};

/**
 * An Album.
 * Albums are selections of sleep aid units.
 *
 */
export type Album = {
  __typename?: 'Album';
  /** The description of the album */
  description: Scalars['String']['output'];
  /** The items of this album, currently SleepAids only */
  items: Array<SleepAidUnit>;
  /** The overall count of SleepUnits inside this Album */
  itemsCount: Scalars['Int']['output'];
  /** Show a nice NEW! badge */
  markedAsNew: Scalars['Boolean']['output'];
  /** Sleep mode images for this album. */
  sleepImages: SleepImages;
  /** The title of the album */
  title: Scalars['String']['output'];
  uuid: Scalars['ID']['output'];
};

/**
 * Audio content that is associated with sleep statistics results
 *
 */
export type AssociatedUnits = {
  __typename?: 'AssociatedUnits';
  /** suggested unit for ‘good‘  */
  good: Unit;
  /** suggested unit for ‘great‘  */
  great: Unit;
  /** suggested unit for ‘okay‘  */
  okay: Unit;
  uuid: Scalars['ID']['output'];
};

/** Autogenerated return type of AttendInsuranceCooperationMutation */
export type AttendInsuranceCooperationMutationPayload = {
  __typename?: 'AttendInsuranceCooperationMutationPayload';
  user?: Maybe<User>;
};

/**
 * Represents audios that belong to a unit.
 *
 */
export type Audio = {
  __typename?: 'Audio';
  /** The file size of the audio in bytes */
  fileByteSize?: Maybe<Scalars['Int']['output']>;
  /** The file size of the audio as human readable string */
  fileSize?: Maybe<Scalars['String']['output']>;
  /** The file URL type the audio as human readable string */
  fileUrl?: Maybe<Scalars['String']['output']>;
  /** The intro length of the audio */
  introLength?: Maybe<Scalars['Int']['output']>;
  /** The overall length of the audio */
  length?: Maybe<Scalars['Int']['output']>;
  /** The speaker name of the audio */
  speaker: Scalars['String']['output'];
  uuid: Scalars['ID']['output'];
};

/**
 * Images for awake mode
 *
 */
export type AwakeImages = {
  __typename?: 'AwakeImages';
  /** the URL of the illustration */
  illustration: Scalars['String']['output'];
  /** the URL of the thumbnail */
  thumbnail: Scalars['String']['output'];
  uuid: Scalars['ID']['output'];
};

/**
 * Represents an awake phase
 *
 */
export type AwakePhase = {
  __typename?: 'AwakePhase';
  /** The time the user sleeps again when the awake phase is finished */
  sleepTime: Scalars['ISO8601DateTime']['output'];
  uuid: Scalars['ID']['output'];
  /** The time the user wakes up in the main sleep as awake phase */
  wakeTime: Scalars['ISO8601DateTime']['output'];
};

/**
 * Input Type for awake phases
 *
 */
export type AwakePhaseInput = {
  /** The time the user sleeps again when the awake phase is finished */
  sleepTime: Scalars['ISO8601DateTime']['input'];
  /** The time the user wakes up in the main sleep as awake phase */
  wakeTime: Scalars['ISO8601DateTime']['input'];
};

export type BillingAddress = {
  __typename?: 'BillingAddress';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  uuid: Scalars['ID']['output'];
  zipCode?: Maybe<Scalars['String']['output']>;
};

export enum Cancellation {
  OnTime = 'ON_TIME',
  Reasoned = 'REASONED'
}

export enum CancellationDate {
  Earliest = 'EARLIEST',
  EndOfTerm = 'END_OF_TERM',
  Requested = 'REQUESTED'
}

export type CancellationRequest = {
  __typename?: 'CancellationRequest';
  /** Download URL of the confirmation pdf */
  confirmationPdfUrl: Scalars['String']['output'];
  /** Email address of the request */
  email: Scalars['String']['output'];
  /** Date and Time when the cancellation request was received */
  receivedDate: Scalars['ISO8601DateTime']['output'];
  uuid: Scalars['ID']['output'];
};

/**
 * Represents a specific content Category
 *
 */
export type Category = {
  __typename?: 'Category';
  /** The category_units of this category */
  categoryUnits: Array<CategoryUnit>;
  /** The description of the category */
  description: Scalars['String']['output'];
  /** Show a nice NEW! badge */
  markedAsNew: Scalars['Boolean']['output'];
  /** The priority of the category */
  position: Scalars['Int']['output'];
  /** The title in a shorter format, e.g. for displaying downloads */
  shortTitle: Scalars['String']['output'];
  /** Sleep mode images for this unit */
  sleepImages: SleepImages;
  /** The title of the category */
  title: Scalars['String']['output'];
  uuid: Scalars['ID']['output'];
};

/**
 * A CategoryUnit
 *
 * CategoryUnits do belong to a Category and do not have a Group.
 *
 */
export type CategoryUnit = Unit & {
  __typename?: 'CategoryUnit';
  /** The artist of the associated audio of the unit */
  artist: Scalars['String']['output'];
  /** The author of the associated audio of the unit */
  author?: Maybe<Scalars['String']['output']>;
  /** The category this unit belongs to */
  category: Category;
  /** Completion of the unit */
  completed: Scalars['Boolean']['output'];
  /** Some descriptive text */
  description: Scalars['String']['output'];
  /** The display type of the unit */
  displayType: CategoryUnitDisplay;
  /** The associated audio of the unit */
  file?: Maybe<Scalars['String']['output']>;
  /** The size of the attached audio file in a human readable format */
  fileSize?: Maybe<Scalars['String']['output']>;
  /** The (optional) length of an intro in the audio in seconds */
  introLength?: Maybe<Scalars['Int']['output']>;
  /** The latest progress of the unit by the current user */
  latestProgress?: Maybe<Progress>;
  /** The length of audio in seconds */
  length: Scalars['Int']['output'];
  /** Unit availability */
  locked: Scalars['Boolean']['output'];
  /** Show a nice NEW! badge */
  markedAsNew: Scalars['Boolean']['output'];
  /** The position of the unit, related to the parent (Category or Group) */
  position: Scalars['Int']['output'];
  /**  Sleep mode images for this unit. Fallbacks from the Schlafhilfe Kategorie */
  sleepImages: SleepImages;
  /** The title of the unit */
  title: Scalars['String']['output'];
  uuid: Scalars['ID']['output'];
};

export enum CategoryUnitDisplay {
  /** Entity available when condition 'sleep_aid' is met */
  SleepAid = 'SLEEP_AID',
  /** Entity available when condition 'theory' is met */
  Theory = 'THEORY'
}

/**
 * Entrypoint for Collections, which are selected Albums of Sleep Aid Units
 *
 */
export type Collection = {
  __typename?: 'Collection';
  /** The albums of this Collection */
  albums: Array<Album>;
  /** The description of the group */
  description?: Maybe<Scalars['String']['output']>;
  /** The overall position/priority of the Collection */
  position: Scalars['Int']['output'];
  /** The title of the group */
  title: Scalars['String']['output'];
  uuid: Scalars['ID']['output'];
};

/** Autogenerated return type of ConfirmEmailAddressChangeMutation */
export type ConfirmEmailAddressChangeMutationPayload = {
  __typename?: 'ConfirmEmailAddressChangeMutationPayload';
  user?: Maybe<User>;
};

/** Autogenerated return type of ConnectPurchaseMutation */
export type ConnectPurchaseMutationPayload = {
  __typename?: 'ConnectPurchaseMutationPayload';
  /** The User with the new paying status */
  user: User;
};

/**
 * Content (Audio Units) that are related to the levels from the sleep statistics ratings
 *
 */
export type Content = {
  __typename?: 'Content';
  /** Audio Units for `bed time`, grouped by rating */
  bedTime: AssociatedUnits;
  /** Audio Units for `sleep efficiency`, grouped by rating */
  efficiency: AssociatedUnits;
  /** Audio Units for `sleep duration`, grouped by rating */
  sleepDuration: AssociatedUnits;
  uuid: Scalars['ID']['output'];
};

/**
 * Represents a Content Card of a GroupUnit
 *
 */
export type ContentCard = {
  __typename?: 'ContentCard';
  /** The text element 1 */
  textElement1: Scalars['String']['output'];
  /** The text element 2 */
  textElement2?: Maybe<Scalars['String']['output']>;
  /** The text element 3 */
  textElement3?: Maybe<Scalars['String']['output']>;
  /** The text element 4 */
  textElement4?: Maybe<Scalars['String']['output']>;
  /** The text element 5 */
  textElement5?: Maybe<Scalars['String']['output']>;
  /** The text element 6 */
  textElement6?: Maybe<Scalars['String']['output']>;
  /** The title of the content card */
  title: Scalars['String']['output'];
  uuid: Scalars['ID']['output'];
};

/** Represents a time in a simple HH:MM format, expects a string representation like "01:00" */
export type DayTime = {
  __typename?: 'DayTime';
  /** hours */
  hour: Scalars['Int']['output'];
  /** minutes */
  minute: Scalars['Int']['output'];
  uuid: Scalars['ID']['output'];
};

/** Represents a simple time of a day (HH:MM) */
export type DayTimeInput = {
  /** hours */
  hour?: InputMaybe<Scalars['Int']['input']>;
  /** minutes */
  minute?: InputMaybe<Scalars['Int']['input']>;
};

export enum DeviceOs {
  /** app device os android */
  Android = 'android',
  /** app device os ios */
  Ios = 'ios'
}

export enum EntryType {
  /** Entry Type 'FIRST_VIEW' */
  FirstView = 'FIRST_VIEW',
  /** Entry Type 'PROGRESSED' */
  Progressed = 'PROGRESSED',
  /** Entry Type 'RESTART' */
  Restart = 'RESTART'
}

/**
 * Represents a specific Exercise (Abendroutine)
 *
 */
export type ExerciseUnit = Unit & {
  __typename?: 'ExerciseUnit';
  /** The artist of the associated audio of the unit */
  artist: Scalars['String']['output'];
  /** Completion of the unit */
  completed: Scalars['Boolean']['output'];
  /** True if the ExerciseUnit is the default content */
  defaultContent: Scalars['Boolean']['output'];
  /** Some descriptive text */
  description: Scalars['String']['output'];
  /** The display type of the unit */
  displayType: ExerciseUnitDisplay;
  /** The content card of the group unit */
  exerciseCard: ContentCard;
  /** The associated audio of the unit */
  file?: Maybe<Scalars['String']['output']>;
  /** The size of the attached audio file in a human readable format */
  fileSize?: Maybe<Scalars['String']['output']>;
  /** Illustration of this unit, sleep mode only. */
  illustration: SleepIllustration;
  /** The (optional) length of an intro in the audio in seconds */
  introLength?: Maybe<Scalars['Int']['output']>;
  /** The latest progress of the unit by the current user */
  latestProgress?: Maybe<Progress>;
  /** The length of audio in seconds */
  length: Scalars['Int']['output'];
  /** Unit availability */
  locked: Scalars['Boolean']['output'];
  /** The position of the unit, related to the parent (Category or Group) */
  position: Scalars['Int']['output'];
  /** The title of the unit */
  title: Scalars['String']['output'];
  uuid: Scalars['ID']['output'];
};

export enum ExerciseUnitDisplay {
  /** Entity available when condition 'evening_exercise' is met */
  EveningExercise = 'EVENING_EXERCISE',
  /** Entity available when condition 'intro' is met */
  Intro = 'INTRO'
}

export enum Gender {
  Diverse = 'DIVERSE',
  Female = 'FEMALE',
  Male = 'MALE',
  Unknown = 'UNKNOWN'
}

/**
 * Represents a specific content group and its resources
 *
 */
export type Group = {
  __typename?: 'Group';
  /** The awake mode images for this group */
  awakeImages: AwakeImages;
  /** The description of the group */
  description: Scalars['String']['output'];
  /** The group_units of this group */
  groupUnits: Array<GroupUnit>;
  /** The number of user-completed group_units inside this group */
  groupUnitsCompletedCount: Scalars['Int']['output'];
  /** The number of group_units inside this group */
  groupUnitsCount: Scalars['Int']['output'];
  /** Whether the group is not fast forwardable */
  isNotFastForwardable: Scalars['Boolean']['output'];
  /** Show a nice NEW! badge */
  markedAsNew: Scalars['Boolean']['output'];
  /** The priority of the group */
  position: Scalars['Int']['output'];
  /** The section of this group */
  section: GroupSections;
  /** The sleep mode images for this group */
  sleepImages: SleepImages;
  /** The title of the group */
  title: Scalars['String']['output'];
  uuid: Scalars['ID']['output'];
};

export enum GroupSections {
  /** Entity available when condition 'advanced' is met */
  Advanced = 'ADVANCED',
  /** Entity available when condition 'main_course' is met */
  MainCourse = 'MAIN_COURSE'
}

/**
 * A GroupUnit
 *
 * GroupUnits do belong to a Group and do not have a Category.
 *
 */
export type GroupUnit = Unit & {
  __typename?: 'GroupUnit';
  /** The artist of the associated audio of the unit */
  artist: Scalars['String']['output'];
  /**  Awake mode images for this unit. Fallbacks from the Group (Kapitel) */
  awakeImages: AwakeImages;
  /** Completion of the unit */
  completed: Scalars['Boolean']['output'];
  /** The content card of the group unit */
  contentCard: ContentCard;
  /** Some descriptive text */
  description: Scalars['String']['output'];
  /** The display type of the unit */
  displayType: GroupUnitDisplay;
  /** The associated audio of the unit */
  file?: Maybe<Scalars['String']['output']>;
  /** The size of the attached audio file in a human readable format */
  fileSize?: Maybe<Scalars['String']['output']>;
  /** The group this unit belongs to */
  group: Group;
  /** The (optional) length of an intro in the audio in seconds */
  introLength?: Maybe<Scalars['Int']['output']>;
  /** The latest progress of the unit by the current user */
  latestProgress?: Maybe<Progress>;
  /** The length of audio in seconds */
  length: Scalars['Int']['output'];
  /** Unit availability */
  locked: Scalars['Boolean']['output'];
  /** The position of the unit, related to the parent (Category or Group) */
  position: Scalars['Int']['output'];
  /** Sleep mode images for this unit. Fallbacks from the Group (Kapitel) */
  sleepImages: SleepImages;
  /** The title of the unit */
  title: Scalars['String']['output'];
  uuid: Scalars['ID']['output'];
};

export enum GroupUnitDisplay {
  /** Entity available when condition 'benefit' is met */
  Benefit = 'BENEFIT',
  /** Entity available when condition 'knowledge' is met */
  Knowledge = 'KNOWLEDGE',
  /** Entity available when condition 'myth' is met */
  Myth = 'MYTH',
  /** Entity available when condition 'training' is met */
  Training = 'TRAINING'
}

/**
 * A section of Highlights
 *
 */
export type Highlight = {
  __typename?: 'Highlight';
  /** The items of this album, currently SleepAids only */
  items: Array<SleepAidUnit>;
  /** The name of the highlight section */
  name: HighlightName;
  /** The title of the highlight section */
  title: Scalars['String']['output'];
  uuid: Scalars['ID']['output'];
};

export enum HighlightName {
  /** Highlight named 'LASTLY_LISTENED' */
  LastlyListened = 'LASTLY_LISTENED',
  /** Highlight named 'MARKED_AS_NEW' */
  MarkedAsNew = 'MARKED_AS_NEW',
  /** Highlight named 'MARKED_AS_POPULAR' */
  MarkedAsPopular = 'MARKED_AS_POPULAR'
}

/** Represents an insurance company */
export type InsuranceCompany = {
  __typename?: 'InsuranceCompany';
  /** The cooperation logo */
  cooperationLogo?: Maybe<Scalars['String']['output']>;
  /** The indicator being a cooperation partner */
  cooperationPartner?: Maybe<Scalars['Boolean']['output']>;
  /** The cooperation url */
  cooperationUrl?: Maybe<Scalars['String']['output']>;
  /** The insurance company name */
  name: Scalars['String']['output'];
  /** The reduction rate in percent */
  reductionRate: Scalars['Int']['output'];
  uuid: Scalars['ID']['output'];
};

export enum Interval {
  /** Interval for the subscription 'monthly' */
  Monthly = 'MONTHLY',
  /** Interval for onetime purchases */
  Onetime = 'ONETIME',
  /** Interval for the subscription 'yearly' */
  Yearly = 'YEARLY'
}

/**
 * Represents a Logbook
 *
 */
export type Logbook = {
  __typename?: 'Logbook';
  /** a list of logbook days */
  days: Array<LogbookDay>;
  uuid: Scalars['ID']['output'];
};


/**
 * Represents a Logbook
 *
 */
export type LogbookDaysArgs = {
  endDate?: InputMaybe<Scalars['SimpleDate']['input']>;
  startDate: Scalars['SimpleDate']['input'];
};

/**
 * Represents a specific day in the Logbook
 *
 */
export type LogbookDay = {
  __typename?: 'LogbookDay';
  /** The date of the Logbook day */
  calendarDate: Scalars['SimpleDate']['output'];
  /** The main sleep phase of the user */
  mainSleep?: Maybe<MainSleep>;
  /** Power naps: optional short sleeps over the day */
  powerNaps?: Maybe<Array<PowerNap>>;
  uuid: Scalars['ID']['output'];
  /** The well being of the user today */
  wellBeing?: Maybe<WellBeing>;
};

/**
 * Represents a specific day in the Logbook as Input Type
 *
 */
export type LogbookDayInput = {
  /** The main sleep phase of the user */
  mainSleep?: InputMaybe<MainSleepInput>;
  /** Power naps: optional short sleeps over the day */
  powerNaps?: InputMaybe<Array<PowerNapInput>>;
  /** The well being of the user today */
  wellBeing?: InputMaybe<WellBeing>;
};

/**
 * Represents the main sleep interval, typically the nightly sleep
 *
 */
export type MainSleep = {
  __typename?: 'MainSleep';
  /** Awake Phases: optional short awake phases in the main sleep */
  awakePhases: Array<AwakePhase>;
  /** The time the user goes to bed */
  bedTime: Scalars['ISO8601DateTime']['output'];
  /** The time the user gets up */
  getUpTime: Scalars['ISO8601DateTime']['output'];
  /** The sleep quality rated by the user */
  sleepQuality?: Maybe<SleepQuality>;
  /** The time the user falls asleep */
  sleepTime?: Maybe<Scalars['ISO8601DateTime']['output']>;
  uuid: Scalars['ID']['output'];
  /** The time the user wakes up */
  wakeTime?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

/**
 * Input Type for the main sleep phase
 *
 */
export type MainSleepInput = {
  /** Awake Phases: optional short awake phases in the main sleep */
  awakePhases?: InputMaybe<Array<AwakePhaseInput>>;
  /** The time the user goes to bed */
  bedTime: Scalars['ISO8601DateTime']['input'];
  /** The time the user gets up */
  getUpTime: Scalars['ISO8601DateTime']['input'];
  /** The sleep quality rated by the user */
  sleepQuality?: InputMaybe<SleepQuality>;
  /** The time the user falls asleep */
  sleepTime?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  /** The time the user wakes up */
  wakeTime?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
};

/**
 * Money
 *
 * A money representation of a Price
 *
 */
export type Money = {
  __typename?: 'Money';
  /** The price in cents */
  cents: Scalars['Int']['output'];
  /** The currency as ISO 4217 code (like EUR) */
  currency: Scalars['String']['output'];
  /** Localized price based on the request locale */
  localizedPrice: Scalars['String']['output'];
  uuid: Scalars['ID']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /**
   * Attend a user to an insurance cooperation course with standard features.
   *
   * A JWT Token needs to be provided.
   *
   */
  attendInsuranceCooperation?: Maybe<AttendInsuranceCooperationMutationPayload>;
  /** Deletes an authentication method from the user */
  cancelSocialAuthentication?: Maybe<User>;
  /**
   * Confirms the email address via the received code
   *
   */
  confirmEmailAddressChange?: Maybe<ConfirmEmailAddressChangeMutationPayload>;
  /**
   * Connects a (Stripe) Payable or VoucherRedemption via PurchaseToken to the User of this Call
   *
   */
  connectPurchase?: Maybe<ConnectPurchaseMutationPayload>;
  /** Creates a new progress. */
  createProgress?: Maybe<Progress>;
  /**
   * Deletes the current user
   *
   */
  deleteUserAccount?: Maybe<Scalars['Boolean']['output']>;
  /** Links an existing user with Facebook */
  linkUserWithApple?: Maybe<RegisterPayload>;
  /** Links an existing user with Facebook */
  linkUserWithFacebook?: Maybe<RegisterPayload>;
  /** Links an existing user with Facebook */
  linkUserWithGoogle?: Maybe<RegisterPayload>;
  /**
   * Purchase an offer
   *
   * A store product (iTunes and playstore only) can be purchased via the API with a reference
   * to the offer containing the product.
   *
   */
  purchaseOffer?: Maybe<PurchaseOfferMutationPayload>;
  /**
   * Fullfill a Stripe purchase of an offer
   *
   */
  purchaseStripeOffer?: Maybe<PurchaseStripeOfferMutationPayload>;
  /**
   * Redeems a voucher
   *
   */
  redeemVoucher?: Maybe<RedeemVoucherMutationPayload>;
  /** Registers a new user using a Apple id_token. */
  registerUserWithApple?: Maybe<SignInPayload>;
  /** Registers a user using a Facebook. */
  registerUserWithFacebook?: Maybe<SignInPayload>;
  /** Registers a new user using a Google id_token. */
  registerUserWithGoogle?: Maybe<SignInPayload>;
  /**
   * Register a User with personal data and send a Code to signIn.
   *
   * requires: a device JWT-Token
   *
   */
  registerUserWithOnetimePassword?: Maybe<RegisterPayload>;
  /**
   * Initializes an OTP-SignIn without requiring a device authentication as in other calls.
   * Creates a new User with the given email or reuses a existing one. Sends a OTP Mail to the user.
   * Always returns `null` as result.
   * Does not require a JWT-Token!
   *
   */
  registerWebUser?: Maybe<RegisterWebUserMutationPayload>;
  /** Creates a CancellationRequest */
  requestCancellation?: Maybe<CancellationRequest>;
  /**
   * Request the email to be changed.
   *
   * A code is sent to the new email, which is needed to confirm the new email in a second call!
   *
   */
  requestEmailAddressChange?: Maybe<RequestEmailAddressChangeMutationPayload>;
  /**
   * Resend a purchase token for a stripe purchase or voucher_redemption
   *
   */
  resendPurchaseToken?: Maybe<ResendPurchaseTokenMutationPayload>;
  /**
   * Cancels the process of an email change request, invalidating any code that might have been sent.
   *
   */
  revokeEmailAddressChangeRequest?: Maybe<RevokeEmailAddressChangeRequestMutationPayload>;
  /**
   * Sends a Onetime Password (Token) to the given E-Mail.
   *
   * The Onetime Password can be exchanged to a JWT-Token.
   * requires: a device JWT-Token
   *
   */
  sendOnetimePassword?: Maybe<SendOnetimePasswordMutationPayload>;
  /**
   * SignIn a API consumer (device) and retrieve a JWT token.
   *
   * The JWT token can be used to communicate with the API (as an unregistered user).
   * requires: -
   *
   */
  signInDevice?: Maybe<SignInPayload>;
  /**
   * SignIn a API consumer via Apple and retrieve a JWT token.
   *
   * Requires:
   *   - Apple user id
   *   - Apple access token
   *
   */
  signInUserWithApple?: Maybe<SignInPayload>;
  /**
   * SignIn a API consumer via Facebook and retrieve a JWT token.
   *
   * Requires:
   *   - Facebook user id
   *   - Facebook access token
   *
   */
  signInUserWithFacebook?: Maybe<SignInPayload>;
  /** SignIn a API consumer via Google using an id_token */
  signInUserWithGoogle?: Maybe<SignInPayload>;
  /**
   * Authenticates an user with an onetime password and returns a JWT token.
   * A onetime password is delivered via e-mail and can be requested upon registration.
   * The JWT-Token can be used to communicate with the API (as a registered user).
   *
   * This Mutation is also used to sign-in a web user (signInWebUser) in the same way, but without a device token protection.
   *
   *
   */
  signInUserWithOnetimePassword?: Maybe<SignInPayload>;
  /**
   * This is the public variant of the `SignInUserWithOnetimePasswordMutation` which does not require a JWT-Token.
   * It is used to sign in a web-user with a onetime password and returns a JWT token.
   *
   */
  signInWebUser?: Maybe<SignInPayload>;
  /**
   * DEPRECATED -- Stores or updates the appsflyer id for the current user. The result is true or false,
   * depending on whether the submitted customUserId matches the UUID of the authenticated user.
   *
   */
  submitAppsFlyerId?: Maybe<Scalars['Boolean']['output']>;
  /**
   * Updates a Logbook Day.
   *
   * This is the main mutation to create and update the following entities/properties of a logbook day:
   * - logbook_day
   *   - well_being
   *   - power_naps
   *   - main_sleep
   *   - awake_phases
   *
   */
  updateLogbookDay?: Maybe<UpdateLogbookDayMutationPayload>;
  /** Updates a user. */
  updateUser?: Maybe<UpdateUserMutationPayload>;
  uuid: Scalars['ID']['output'];
  /**
   * Validates an insurance number to be a valid insuracne number and to not exist already.
   * Given an E-Mail, it is allowed to exist for the E-Mail only.
   *
   */
  validateInsuranceNumber?: Maybe<Scalars['Boolean']['output']>;
};


export type MutationAttendInsuranceCooperationArgs = {
  age?: InputMaybe<Scalars['Int']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['ISO8601Date']['input']>;
  gender?: InputMaybe<Gender>;
  insuranceNumber?: InputMaybe<Scalars['String']['input']>;
  voucherId: Scalars['String']['input'];
};


export type MutationCancelSocialAuthenticationArgs = {
  kind: UserSocialAuthenticationName;
};


export type MutationConfirmEmailAddressChangeArgs = {
  token: Scalars['String']['input'];
};


export type MutationConnectPurchaseArgs = {
  token: Scalars['String']['input'];
};


export type MutationCreateProgressArgs = {
  audioId?: InputMaybe<Scalars['String']['input']>;
  completenessInSeconds: Scalars['Int']['input'];
  recordedAt: Scalars['ISO8601DateTime']['input'];
  timeInSeconds: Scalars['Int']['input'];
  unitId: Scalars['String']['input'];
};


export type MutationLinkUserWithAppleArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type MutationLinkUserWithFacebookArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type MutationLinkUserWithGoogleArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type MutationPurchaseOfferArgs = {
  offerId: Scalars['String']['input'];
  originalTransactionId?: InputMaybe<Scalars['String']['input']>;
  receipt: Scalars['String']['input'];
  restore?: InputMaybe<Scalars['Boolean']['input']>;
  transactionId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationPurchaseStripeOfferArgs = {
  couponCode?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  offerId: Scalars['String']['input'];
  paymentMethod: Scalars['String']['input'];
};


export type MutationRedeemVoucherArgs = {
  code: Scalars['String']['input'];
  email: Scalars['String']['input'];
};


export type MutationRegisterUserWithAppleArgs = {
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  termsAccepted: Scalars['Boolean']['input'];
  token: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type MutationRegisterUserWithFacebookArgs = {
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  termsAccepted: Scalars['Boolean']['input'];
  token: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type MutationRegisterUserWithGoogleArgs = {
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  termsAccepted: Scalars['Boolean']['input'];
  token: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type MutationRegisterUserWithOnetimePasswordArgs = {
  email: Scalars['String']['input'];
  nickname?: InputMaybe<Scalars['String']['input']>;
  termsAccepted: Scalars['Boolean']['input'];
};


export type MutationRegisterWebUserArgs = {
  email: Scalars['String']['input'];
  nickname?: InputMaybe<Scalars['String']['input']>;
  termsAccepted: Scalars['Boolean']['input'];
};


export type MutationRequestCancellationArgs = {
  cancellationDateType?: InputMaybe<CancellationDate>;
  cancellationReason?: InputMaybe<Scalars['String']['input']>;
  cancellationType: Cancellation;
  email: Scalars['String']['input'];
  product: Product;
  requestedCancellationAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
};


export type MutationRequestEmailAddressChangeArgs = {
  email: Scalars['String']['input'];
};


export type MutationResendPurchaseTokenArgs = {
  email: Scalars['String']['input'];
};


export type MutationRevokeEmailAddressChangeRequestArgs = {
  email: Scalars['String']['input'];
};


export type MutationSendOnetimePasswordArgs = {
  email: Scalars['String']['input'];
};


export type MutationSignInDeviceArgs = {
  identifier: Scalars['String']['input'];
};


export type MutationSignInUserWithAppleArgs = {
  token: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type MutationSignInUserWithFacebookArgs = {
  token: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type MutationSignInUserWithGoogleArgs = {
  token: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type MutationSignInUserWithOnetimePasswordArgs = {
  email: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationSignInWebUserArgs = {
  email: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationSubmitAppsFlyerIdArgs = {
  appsFlyerId: Scalars['String']['input'];
  customerUserId?: InputMaybe<Scalars['String']['input']>;
  deviceOs: DeviceOs;
};


export type MutationUpdateLogbookDayArgs = {
  input: LogbookDayInput;
  key: Scalars['SimpleDate']['input'];
};


export type MutationUpdateUserArgs = {
  askedForReviewAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  bedTime?: InputMaybe<DayTimeInput>;
  eveningExerciseId?: InputMaybe<Scalars['String']['input']>;
  eveningRoutineNotificationTime?: InputMaybe<DayTimeInput>;
  getUpTime?: InputMaybe<DayTimeInput>;
  localeSelection?: InputMaybe<Scalars['String']['input']>;
  morningRoutineNotificationTime?: InputMaybe<DayTimeInput>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  onboardingCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  reviewedAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  sleepTopics?: InputMaybe<Array<UserSleepTopics>>;
  trackingDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  viewedHelpScreens?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type MutationValidateInsuranceNumberArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  insuranceNumber: Scalars['String']['input'];
};

/**
 * An Offer is something which can be sold via a provider and there are 2
 * different Types which are Offers: SinglePaymentOffers and SubscriptionOffers.
 *
 */
export type Offer = {
  /** The country_code of the Offer */
  countryCode?: Maybe<OfferCountryCode>;
  /** Information about the Stripe coupon (if present) */
  coupon?: Maybe<StripeCoupon>;
  /** The original price of the Offer, to be displayed crossed-out */
  crossedOutPrice?: Maybe<Money>;
  /** The name of the offer which is presented to the user */
  displayName: Scalars['String']['output'];
  /** A group_name for Offers. Only one Offer in a group can be purchased */
  groupName: Scalars['String']['output'];
  /** TRUE if this Offer is marked as highlighted */
  highlight: Scalars['Boolean']['output'];
  /** The (internal) name of the Offer */
  name: Scalars['String']['output'];
  /** The position of the Offer, when displayed in groups */
  position: Scalars['Int']['output'];
  /** The price of the Offer */
  price: Money;
  /** The provider of the Offer */
  provider: OfferProvider;
  /** The Id of the Offer to be identified at the provider */
  providerId: Scalars['String']['output'];
  /** The offer is purchased from the current_user */
  purchased?: Maybe<Scalars['Boolean']['output']>;
  /** TRUE if tax rate is included, false when not */
  taxInclusive: Scalars['Boolean']['output'];
  /** The percentage value of the price */
  taxRatePercentage?: Maybe<Scalars['Int']['output']>;
  uuid: Scalars['ID']['output'];
  /** Special Offer which is certified by ZPP */
  zppCertified: Scalars['Boolean']['output'];
};

export enum OfferCountryCode {
  /** Country Code 'AT' */
  At = 'AT',
  /** Country Code 'CH' */
  Ch = 'CH',
  /** Country Code 'DE' */
  De = 'DE'
}

export enum OfferProvider {
  /** Payment provider 'itunes' */
  Itunes = 'ITUNES',
  /** Payment provider 'playstore' */
  Playstore = 'PLAYSTORE',
  /** Payment provider 'stripe' */
  Stripe = 'STRIPE',
  /** Payment provider 'voucher' */
  Voucher = 'VOUCHER'
}

/**
 * A representation of several payable details.
 *
 * A payable can be a subscription or a one-time payment.
 *
 * For subscriptions it includes also the renewal details.
 *
 * If 'paymentDetails' or 'renewalInfo' are selected, several external stripe calls need to perform, which delays the response. Select these fields only if really needed.
 * For example in a second call for a more detailed view.
 *
 */
export type PayableInfo = {
  __typename?: 'PayableInfo';
  /** Whether the payable is active and unlocks paid content */
  active: Scalars['Boolean']['output'];
  /** For subscriptions only. Whether the subscription was canceled. Can still be active even it is already canceled. */
  canceled: Scalars['Boolean']['output'];
  /** For subscriptions only. End date_time of the current subscription period */
  endsAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  interval?: Maybe<Interval>;
  payableType: PayableType;
  /** Payment and invoice details of the last payment (Stripe only). SELECT ONLY IF REALLY NEEDED */
  paymentDetails?: Maybe<PaymentDetails>;
  /** Product Name */
  product: Scalars['String']['output'];
  /** Payment provider */
  provider: OfferProvider;
  /** Renewal date_time, amount and payment method (Stripe only). SELECT ONLY IF REALLY NEEDED */
  renewalInfo?: Maybe<RenewalInfo>;
  /** Start date_time of the current subsciption period or purchase date_time */
  startsAt: Scalars['ISO8601DateTime']['output'];
  uuid: Scalars['ID']['output'];
};

export enum PayableType {
  /** Payable is a one time payment */
  OneTimePayment = 'ONE_TIME_PAYMENT',
  /** Payable is a subscription */
  Subscription = 'SUBSCRIPTION',
  /** Payable is a voucher redemption */
  VoucherRedemption = 'VOUCHER_REDEMPTION'
}

export type PaymentDetails = {
  __typename?: 'PaymentDetails';
  address?: Maybe<BillingAddress>;
  amount?: Maybe<Money>;
  invoicePdfUrl?: Maybe<Scalars['String']['output']>;
  paymentMethod?: Maybe<StripePaymentMethod>;
  uuid: Scalars['ID']['output'];
};

/**
 * Represents power naps
 *
 */
export type PowerNap = {
  __typename?: 'PowerNap';
  /** The time the user falls asleep */
  sleepTime: Scalars['ISO8601DateTime']['output'];
  uuid: Scalars['ID']['output'];
  /** The time the user wakes up */
  wakeTime: Scalars['ISO8601DateTime']['output'];
};

/**
 * Input Type for power naps
 *
 */
export type PowerNapInput = {
  /** The time the user falls asleep */
  sleepTime: Scalars['ISO8601DateTime']['input'];
  /** The time the user wakes up */
  wakeTime: Scalars['ISO8601DateTime']['input'];
};

export enum Product {
  Custom = 'CUSTOM',
  Monthly = 'MONTHLY',
  Onetime = 'ONETIME',
  Quarterly = 'QUARTERLY',
  Yearly = 'YEARLY'
}

/** Represents a progress of a user in an unit. */
export type Progress = {
  __typename?: 'Progress';
  /** The audio object – if there are multiple audios (e.g. SleepAidUnits) */
  audio?: Maybe<Audio>;
  /** Completion of the unit */
  completed: Scalars['Boolean']['output'];
  /** The point of the unit where the progress stopped */
  completenessInSeconds: Scalars['Int']['output'];
  /** The timestamp when the progress was recorded */
  recordedAt: Scalars['ISO8601DateTime']['output'];
  /** The duration in seconds of this particular progress */
  timeInSeconds: Scalars['Int']['output'];
  /** The unit referenced by this progress */
  unit: Unit;
  /** The user who created this progress */
  user: User;
  uuid: Scalars['ID']['output'];
};

/** Autogenerated return type of PurchaseOfferMutation */
export type PurchaseOfferMutationPayload = {
  __typename?: 'PurchaseOfferMutationPayload';
  /** The user which purchased the offer */
  user: User;
};

/** Autogenerated return type of PurchaseStripeOfferMutation */
export type PurchaseStripeOfferMutationPayload = {
  __typename?: 'PurchaseStripeOfferMutationPayload';
  /** The user is already paying for an effective subscription or onetime payment */
  isAlreadyPaying?: Maybe<Scalars['Boolean']['output']>;
  /** The purchase token for users that we can not auto-assign */
  purchaseToken?: Maybe<Scalars['String']['output']>;
  /** True if the purchase has been auto-assigned to an user */
  userAutoAssigned: Scalars['Boolean']['output'];
};

export type Query = {
  __typename?: 'Query';
  /**
   * Returns the content of an album
   *
   */
  album: Album;
  /**
   * Resolves the categories, ordered by position
   *
   */
  categories: Array<Category>;
  /**
   * Returns the content of a category
   *
   */
  category: Category;
  /**
   * [DRAFT] Collections of Albums of Sleep Aid Units
   *
   */
  collections: Array<Collection>;
  /**
   * Resolves the Exercises (currently: Abendroutinen), ordered by position
   *
   */
  exercises: Array<ExerciseUnit>;
  /**
   * Returns the content of a group
   *
   */
  group: Group;
  /**
   * Resolves a GroupUnit
   *
   */
  groupUnit: GroupUnit;
  /**
   * Resolves the Groups (Kapitel), ordered by position.
   *
   *
   */
  groups: Array<Group>;
  /**
   * Highlight Sections
   *
   */
  highlights?: Maybe<Array<Highlight>>;
  /**
   * Contains the content for the App Entry (HomeView)
   *
   */
  homeView?: Maybe<Root>;
  /**
   * The InsuranceCompanies we support (may be filtered by cooperation partnership)
   *
   */
  insuranceCompanies: Array<InsuranceCompany>;
  /**
   * Resolves the logbook, restricted by a date range
   *
   */
  logbook: Logbook;
  /**
   * Resolves the API user, also aliased as Me
   *
   */
  me: User;
  /**
   * Offers, ordered by position.
   * Prices may be reduced by a coupon code.
   *
   */
  offers: Array<Offer>;
  /** Details of the last users subscription and/or onetime-payment (if any) */
  payableInfos: Array<PayableInfo>;
  /**
   * Returns a progress of the current_user
   *
   */
  progress: Progress;
  /**
   * Returns recomendations for the Start tab
   *
   */
  recommendations?: Maybe<Array<Recommendations>>;
  /**
   * Returns the (legacy) recommended SleepAids (CategoryUnits with display_type:sleep_aid)
   *
   */
  recommendedSleepAids: Array<CategoryUnit>;
  /**
   * Returns a sleep aid (by uuid)
   *
   */
  sleepAidUnit: SleepAidUnit;
  /** The static pages */
  staticPages: Array<StaticPage>;
  /**
   * Statistics about the users sleep performance
   *
   */
  statistics: Statistics;
  /** A Stripe Payment Method */
  stripePaymentMethod: StripePaymentMethod;
  /**
   * Stripe Setup Intent
   *
   */
  stripeSetupIntent: StripeSetupIntent;
  /**
   * Returns the content of a Tag
   *
   */
  tag: Tag;
  /**
   * SleepLib Tags
   *
   */
  tags: Array<Tag>;
  /**
   * Resolves the Units, ordered by position
   *
   * @deprecated Generic Units should not be used anymore, use specific types like GroupUnit, ExerciseUnit instead.
   */
  units: Array<Unit>;
  uuid: Scalars['ID']['output'];
};


export type QueryAlbumArgs = {
  uuid: Scalars['String']['input'];
};


export type QueryCategoryArgs = {
  uuid: Scalars['String']['input'];
};


export type QueryGroupArgs = {
  uuid: Scalars['String']['input'];
};


export type QueryGroupUnitArgs = {
  uuid: Scalars['String']['input'];
};


export type QueryGroupsArgs = {
  section?: InputMaybe<GroupSections>;
};


export type QueryHighlightsArgs = {
  name?: InputMaybe<HighlightName>;
};


export type QueryInsuranceCompaniesArgs = {
  isCooperationPartner?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryLogbookArgs = {
  endDate?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  startDate?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
};


export type QueryOffersArgs = {
  couponCode?: InputMaybe<Scalars['String']['input']>;
  groupName?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<OfferProvider>;
};


export type QueryProgressArgs = {
  uuid: Scalars['String']['input'];
};


export type QueryRecommendationsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<RecommendationsName>;
};


export type QuerySleepAidUnitArgs = {
  uuid: Scalars['String']['input'];
};


export type QueryStaticPagesArgs = {
  name?: InputMaybe<StaticPageName>;
};


export type QueryStatisticsArgs = {
  endDate?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  startDate?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
};


export type QueryStripePaymentMethodArgs = {
  paymentMethodId: Scalars['String']['input'];
};


export type QueryTagArgs = {
  uuid: Scalars['String']['input'];
};


export type QueryTagsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
};

/** Recommended lists of items */
export type Recommendation = Album | SleepAidUnit;

/**
 * A section of Recommendations
 *
 */
export type Recommendations = {
  __typename?: 'Recommendations';
  /** The item list of this recommendation */
  items: Array<Recommendation>;
  /** The name of the recommendation section */
  name: RecommendationsName;
  /** The title of the recommendation section */
  title: Scalars['String']['output'];
  uuid: Scalars['ID']['output'];
};

export enum RecommendationsName {
  /** Recommendation named 'DISCOVER_ALBUMS' */
  DiscoverAlbums = 'DISCOVER_ALBUMS',
  /** Recommendation named 'RECOMMENDED_SLEEP_AIDS' */
  RecommendedSleepAids = 'RECOMMENDED_SLEEP_AIDS'
}

/** Autogenerated return type of RedeemVoucherMutation */
export type RedeemVoucherMutationPayload = {
  __typename?: 'RedeemVoucherMutationPayload';
  /** A generated token */
  token?: Maybe<Scalars['String']['output']>;
  /** The Client that is associated with the given code */
  voucherClient?: Maybe<VoucherClient>;
};

/**
 * A successful registration payload.
 *
 * A JWT-Token is not included cause the signIn token is delivered asynchronous
 *
 */
export type RegisterPayload = {
  __typename?: 'RegisterPayload';
  /** The registered User */
  user: User;
};

/** Autogenerated return type of RegisterWebUserMutation */
export type RegisterWebUserMutationPayload = {
  __typename?: 'RegisterWebUserMutationPayload';
  result?: Maybe<Scalars['Boolean']['output']>;
};

export type RenewalInfo = {
  __typename?: 'RenewalInfo';
  amount: Money;
  paymentMethod?: Maybe<StripePaymentMethod>;
  renewalAt: Scalars['ISO8601DateTime']['output'];
  uuid: Scalars['ID']['output'];
};

/** Autogenerated return type of RequestEmailAddressChangeMutation */
export type RequestEmailAddressChangeMutationPayload = {
  __typename?: 'RequestEmailAddressChangeMutationPayload';
  user?: Maybe<User>;
};

/** Autogenerated return type of ResendPurchaseTokenMutation */
export type ResendPurchaseTokenMutationPayload = {
  __typename?: 'ResendPurchaseTokenMutationPayload';
  /** Just an ok */
  status: Scalars['String']['output'];
};

/** Autogenerated return type of RevokeEmailAddressChangeRequestMutation */
export type RevokeEmailAddressChangeRequestMutationPayload = {
  __typename?: 'RevokeEmailAddressChangeRequestMutationPayload';
  user?: Maybe<User>;
};

export type Root = {
  __typename?: 'Root';
  sleepMode: SleepMode;
  uuid: Scalars['ID']['output'];
  wakeMode: WakeMode;
};

/** Autogenerated return type of SendOnetimePasswordMutation */
export type SendOnetimePasswordMutationPayload = {
  __typename?: 'SendOnetimePasswordMutationPayload';
  user?: Maybe<User>;
};

/**
 * A successful signIn payload
 *
 */
export type SignInPayload = {
  __typename?: 'SignInPayload';
  /** JWT-Token to use for further API requests */
  token: Scalars['String']['output'];
  /** The user associated with the JWT-Token */
  user: User;
};

/**
 * A SinglePaymentOffer
 *
 */
export type SinglePaymentOffer = Offer & {
  __typename?: 'SinglePaymentOffer';
  /** The country_code of the Offer */
  countryCode?: Maybe<OfferCountryCode>;
  /** Information about the Stripe coupon (if present) */
  coupon?: Maybe<StripeCoupon>;
  /** The original price of the Offer, to be displayed crossed-out */
  crossedOutPrice?: Maybe<Money>;
  /** The name of the offer which is presented to the user */
  displayName: Scalars['String']['output'];
  /** A group_name for Offers. Only one Offer in a group can be purchased */
  groupName: Scalars['String']['output'];
  /** TRUE if this Offer is marked as highlighted */
  highlight: Scalars['Boolean']['output'];
  /** The (internal) name of the Offer */
  name: Scalars['String']['output'];
  /** The position of the Offer, when displayed in groups */
  position: Scalars['Int']['output'];
  /** The price of the Offer */
  price: Money;
  /** The provider of the Offer */
  provider: OfferProvider;
  /** The Id of the Offer to be identified at the provider */
  providerId: Scalars['String']['output'];
  /** The offer is purchased from the current_user */
  purchased?: Maybe<Scalars['Boolean']['output']>;
  /** TRUE if tax rate is included, false when not */
  taxInclusive: Scalars['Boolean']['output'];
  /** The percentage value of the price */
  taxRatePercentage?: Maybe<Scalars['Int']['output']>;
  uuid: Scalars['ID']['output'];
  /** Special Offer which is certified by ZPP */
  zppCertified: Scalars['Boolean']['output'];
};

/**
 * Represents the new SleepAidUnit 2.0 (Schlafhilfe).
 *
 *
 */
export type SleepAidUnit = Unit & {
  __typename?: 'SleepAidUnit';
  /** The artist of the associated audio of the unit */
  artist: Scalars['String']['output'];
  /** Audios for this sleep aid unit */
  audios?: Maybe<Array<Audio>>;
  /** The author of the associated audio of the unit */
  author?: Maybe<Scalars['String']['output']>;
  /** Completion of the unit */
  completed: Scalars['Boolean']['output'];
  /** Some descriptive text */
  description: Scalars['String']['output'];
  /** The display type of the unit */
  displayType: SleepAidUnitDisplay;
  /** The associated audio of the unit */
  file?: Maybe<Scalars['String']['output']>;
  /** The size of the attached audio file in a human readable format */
  fileSize?: Maybe<Scalars['String']['output']>;
  /** The (optional) length of an intro in the audio in seconds */
  introLength?: Maybe<Scalars['Int']['output']>;
  /** The latest progress of the unit by the current user */
  latestProgress?: Maybe<Progress>;
  /** The length of audio in seconds */
  length: Scalars['Int']['output'];
  /** Unit availability */
  locked: Scalars['Boolean']['output'];
  /** Show a nice NEW! badge */
  markedAsNew: Scalars['Boolean']['output'];
  /** The position of the unit, related to the parent (Category or Group) */
  position: Scalars['Int']['output'];
  /** The label of the Sleep Aid, maintained in the backend */
  sleepAidLabel: Scalars['String']['output'];
  /** Sleep mode images for this sleep aid. */
  sleepImages: SleepImages;
  /** The title of the unit */
  title: Scalars['String']['output'];
  uuid: Scalars['ID']['output'];
};

export enum SleepAidUnitDisplay {
  /** Entity available when condition 'intro' is met */
  Intro = 'INTRO',
  /** Entity available when condition 'sleep_aid' is met */
  SleepAid = 'SLEEP_AID'
}

/**
 * Illustrations of an Category or CategoryUnit
 *
 *
 */
export type SleepIllustration = {
  __typename?: 'SleepIllustration';
  /** the URL of the sleep mode illustration for this unit */
  sleep: Scalars['String']['output'];
  uuid: Scalars['ID']['output'];
};

/**
 * Images for sleep mode
 *
 */
export type SleepImages = {
  __typename?: 'SleepImages';
  /** the URL of the illustration */
  illustration: Scalars['String']['output'];
  /** the URL of the thumbnail */
  thumbnail: Scalars['String']['output'];
  uuid: Scalars['ID']['output'];
};

export type SleepMode = {
  __typename?: 'SleepMode';
  categoryUnit: CategoryUnit;
  entryType: EntryType;
  sleepAidEntryType: EntryType;
  sleepAidUnit: SleepAidUnit;
  uuid: Scalars['ID']['output'];
};

export enum SleepQuality {
  /** Entity available when condition 'five' is met */
  Five = 'FIVE',
  /** Entity available when condition 'four' is met */
  Four = 'FOUR',
  /** Entity available when condition 'one' is met */
  One = 'ONE',
  /** Entity available when condition 'three' is met */
  Three = 'THREE',
  /** Entity available when condition 'two' is met */
  Two = 'TWO'
}

/** Static Pages */
export type StaticPage = {
  __typename?: 'StaticPage';
  /** The language code of the page */
  locale: Scalars['String']['output'];
  /** The Content as markdown */
  markdown: Scalars['String']['output'];
  /** The name of the page */
  name: StaticPageName;
  /** The last update timestamp of the page */
  updatedAt: Scalars['ISO8601DateTime']['output'];
  uuid: Scalars['ID']['output'];
};

export enum StaticPageName {
  /** Static Page name 'imprint' */
  Imprint = 'IMPRINT',
  /** Static Page name 'privacy_policy' */
  PrivacyPolicy = 'PRIVACY_POLICY',
  /** Static Page name 'terms_and_conditions' */
  TermsAndConditions = 'TERMS_AND_CONDITIONS'
}

/**
 * Sleep statistics by day
 *
 */
export type Statistics = {
  __typename?: 'Statistics';
  /**
   * related content for statistics analysis
   *
   */
  content: Content;
  /** a sleep statistics day */
  days: Array<StatisticsDay>;
  uuid: Scalars['ID']['output'];
};


/**
 * Sleep statistics by day
 *
 */
export type StatisticsDaysArgs = {
  endDate?: InputMaybe<Scalars['SimpleDate']['input']>;
  startDate: Scalars['SimpleDate']['input'];
};

/**
 * Represents a day in the Sleep Statistics
 *
 */
export type StatisticsDay = {
  __typename?: 'StatisticsDay';
  /** The time the user went to bed */
  bedTime?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** The daily allover bed time duration in minutes. Includes power naps. Awake phases are bed time, too and not substracted. */
  bedTimeDuration: Scalars['Int']['output'];
  /** The date of the Statistics day AKA LogbookDay calendar date. */
  calendarDate: Scalars['SimpleDate']['output'];
  /**
   * The daily main (nightly) sleep duration in minutes. the calculation is based on `sleepTime` and `wakeTime`,
   * but has a fallback to bed times (`bedTime`, `getUpTime`) if missing.
   * Power naps are not included, but awake phases are substracted.
   *
   */
  mainSleepDuration: Scalars['Int']['output'];
  /** The sleep-in-time, tracked by the user */
  sleepTime?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /**
   * The daily allover sleep duration in minutes, based on `mainSleepDuration`.
   * Includes power naps, excludes awake phases if present.
   *
   */
  totalSleepDuration: Scalars['Int']['output'];
  uuid: Scalars['ID']['output'];
};

/**
 * Card Details
 *
 */
export type StripeCard = {
  __typename?: 'StripeCard';
  /** Brand (visa|mastercard) */
  brand: Scalars['String']['output'];
  /** Country */
  country?: Maybe<Scalars['String']['output']>;
  /** expiry month */
  expMonth: Scalars['String']['output'];
  /** expiry year */
  expYear: Scalars['String']['output'];
  /** Last four digist */
  last4: Scalars['String']['output'];
  uuid: Scalars['ID']['output'];
};

/**
 * Card Details
 *
 */
export type StripeCardPaymentMethod = {
  __typename?: 'StripeCardPaymentMethod';
  /** Card Details */
  card: StripeCard;
  /** The id of the PaymentMethod */
  id: Scalars['String']['output'];
  /** Type (Card|SepaDebit) */
  type: Scalars['String']['output'];
  uuid: Scalars['ID']['output'];
};

/**
 * Coupon Details
 *
 */
export type StripeCoupon = {
  __typename?: 'StripeCoupon';
  /** Amount discount, null if `percentageOff` is present */
  amountOff?: Maybe<Scalars['Float']['output']>;
  /** Duration Type */
  duration: Scalars['String']['output'];
  /** Duration of the coupon in months */
  durationInMonths?: Maybe<Scalars['Int']['output']>;
  /** Public name of the Coupon */
  name: Scalars['String']['output'];
  /** Percentage discount, null if `amountOff` is present */
  percentOff?: Maybe<Scalars['Int']['output']>;
  uuid: Scalars['ID']['output'];
};

/**
 * A StripePaymentMethod
 *
 */
export type StripePaymentMethod = StripeCardPaymentMethod | StripeSepaDebitPaymentMethod;

/**
 * Card Details
 *
 */
export type StripeSepaDebit = {
  __typename?: 'StripeSepaDebit';
  /** Bank Code */
  bankCode?: Maybe<Scalars['String']['output']>;
  /** Country */
  country?: Maybe<Scalars['String']['output']>;
  /** Last four digist */
  last4: Scalars['String']['output'];
  uuid: Scalars['ID']['output'];
};

/**
 * SEPA Details
 *
 */
export type StripeSepaDebitPaymentMethod = {
  __typename?: 'StripeSepaDebitPaymentMethod';
  /** The id of the PaymentMethod */
  id: Scalars['String']['output'];
  /** SEPA Debit Details */
  sepaDebit: StripeSepaDebit;
  /** Type (Card|SepaDebit) */
  type: Scalars['String']['output'];
  uuid: Scalars['ID']['output'];
};

/**
 * A StripeSetupIntent
 *
 * Creates a new SetupIntent within Stripe
 *
 */
export type StripeSetupIntent = {
  __typename?: 'StripeSetupIntent';
  /** The client secret of the StripeSetupIntent */
  clientSecret: Scalars['String']['output'];
  /** The id of the StripeSetupIntent */
  id: Scalars['String']['output'];
  uuid: Scalars['ID']['output'];
};

/**
 * A SubscriptionOffer
 *
 */
export type SubscriptionOffer = Offer & {
  __typename?: 'SubscriptionOffer';
  /** The country_code of the Offer */
  countryCode?: Maybe<OfferCountryCode>;
  /** Information about the Stripe coupon (if present) */
  coupon?: Maybe<StripeCoupon>;
  /** The original price of the Offer, to be displayed crossed-out */
  crossedOutPrice?: Maybe<Money>;
  /** The name of the offer which is presented to the user */
  displayName: Scalars['String']['output'];
  /** A group_name for Offers. Only one Offer in a group can be purchased */
  groupName: Scalars['String']['output'];
  /** TRUE if this Offer is marked as highlighted */
  highlight: Scalars['Boolean']['output'];
  /** The interval of the SubscriptionOffer */
  interval: SubscriptionOfferInterval;
  /** The (internal) name of the Offer */
  name: Scalars['String']['output'];
  /** The position of the Offer, when displayed in groups */
  position: Scalars['Int']['output'];
  /** The price of the Offer */
  price: Money;
  /** The provider of the Offer */
  provider: OfferProvider;
  /** The Id of the Offer to be identified at the provider */
  providerId: Scalars['String']['output'];
  /** The offer is purchased from the current_user */
  purchased?: Maybe<Scalars['Boolean']['output']>;
  /** TRUE if tax rate is included, false when not */
  taxInclusive: Scalars['Boolean']['output'];
  /** The percentage value of the price */
  taxRatePercentage?: Maybe<Scalars['Int']['output']>;
  uuid: Scalars['ID']['output'];
  /** Special Offer which is certified by ZPP */
  zppCertified: Scalars['Boolean']['output'];
};

export enum SubscriptionOfferInterval {
  /** Interval for the subscription 'monthly' */
  Monthly = 'MONTHLY',
  /** Interval for the subscription 'yearly' */
  Yearly = 'YEARLY'
}

/**
 * Tags are selections of sleep aid units.
 *
 */
export type Tag = {
  __typename?: 'Tag';
  /** The items belonging this Tag, currently SleepAids only */
  items: Array<SleepAidUnit>;
  /** The title of the tags */
  title: Scalars['String']['output'];
  uuid: Scalars['ID']['output'];
};


/**
 * Tags are selections of sleep aid units.
 *
 */
export type TagItemsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/**
 * A Unit
 *
 * There are some Fields of a Unit which are are resolved from the Unit itself
 * for a CategoryUnit and handled on the Group for a GroupUnit.
 * The Unit Interface does define the basic fields available for all things which
 * are Unit-like.
 *
 */
export type Unit = {
  /** The artist of the associated audio of the unit */
  artist: Scalars['String']['output'];
  /** Completion of the unit */
  completed: Scalars['Boolean']['output'];
  /** Some descriptive text */
  description: Scalars['String']['output'];
  /** The associated audio of the unit */
  file?: Maybe<Scalars['String']['output']>;
  /** The size of the attached audio file in a human readable format */
  fileSize?: Maybe<Scalars['String']['output']>;
  /** The (optional) length of an intro in the audio in seconds */
  introLength?: Maybe<Scalars['Int']['output']>;
  /** The latest progress of the unit by the current user */
  latestProgress?: Maybe<Progress>;
  /** The length of audio in seconds */
  length: Scalars['Int']['output'];
  /** Unit availability */
  locked: Scalars['Boolean']['output'];
  /** The position of the unit, related to the parent (Category or Group) */
  position: Scalars['Int']['output'];
  /** The title of the unit */
  title: Scalars['String']['output'];
  uuid: Scalars['ID']['output'];
};

/** Autogenerated return type of UpdateLogbookDayMutation */
export type UpdateLogbookDayMutationPayload = {
  __typename?: 'UpdateLogbookDayMutationPayload';
  logbookDay: LogbookDay;
};

/** Autogenerated return type of UpdateUserMutation */
export type UpdateUserMutationPayload = {
  __typename?: 'UpdateUserMutationPayload';
  user?: Maybe<User>;
};

/**
 * A user
 *
 * The main distinction between users are the `registrationStatus` (as there are
 * unregistered users allowed, too) and the `paying` field
 *
 */
export type User = {
  __typename?: 'User';
  /**
   * The last DateTime the App asked the User for a review.
   *         An App needs to check `reviews_enabled` before asking for a review.
   */
  askedForReviewAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** The ususal time the user goes to bed. Stored as HH:MM without any further information. */
  bedTime?: Maybe<DayTime>;
  /** The email of the user */
  email?: Maybe<Scalars['String']['output']>;
  /** The Exercise (Abendroutine) selected by the user (or the default/fallback if there is no Exercise selected */
  eveningExercise?: Maybe<ExerciseUnit>;
  /** The time the user configured for the evening routine notification. Stored as HH:MM without any further information. */
  eveningRoutineNotificationTime?: Maybe<DayTime>;
  /** The usual time the user leaves his bed. Stored as HH:MM without any further information. */
  getUpTime?: Maybe<DayTime>;
  /** The last time the user did signIn */
  lastSignInAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** The latest progresses of the user for any unit */
  latestProgresses: Array<Progress>;
  /** Desired locale for the UI */
  localeSelection?: Maybe<Scalars['String']['output']>;
  /** Days with logbook entries like MainSleep or PowerNaps */
  logbookDays: Array<LogbookDay>;
  /** Mailchimp subscription status */
  mailchimpSubscribed: Scalars['Boolean']['output'];
  /** The time the user configured for the morning routine notification. Stored as HH:MM without any further information. */
  morningRoutineNotificationTime?: Maybe<DayTime>;
  /** The nickname of the user */
  nickname?: Maybe<Scalars['String']['output']>;
  /** True if the user ran through all steps of the onboarding wizard, defaults to false. */
  onboardingCompleted: Scalars['Boolean']['output'];
  /** The registration status User */
  registrationStatus: UserRegistrationStatus;
  /** The DateTime the user has given a review (according to the App - see mutations) */
  reviewedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** True if reviews are enabled for the device */
  reviewsEnabled: Scalars['Boolean']['output'];
  /** Whether the app should show the Promo for the ZPP Course */
  showZppPromo: Scalars['Boolean']['output'];
  /** Choices for sleep topics from onboarding survey. */
  sleepTopics?: Maybe<Array<UserSleepTopics>>;
  /**
   * Known social authentications for this user
   * @deprecated           Authentication information is getting more complex.
   *
   *           Use `user_social_authentications` instead.
   *
   */
  socialAuthentications?: Maybe<Array<UserSocialAuthenticationName>>;
  /** True if user does not want to be tracked */
  trackingDisabled?: Maybe<Scalars['Boolean']['output']>;
  /** Email to which the user wants to change but has not yet confirmed */
  unconfirmedEmail?: Maybe<Scalars['String']['output']>;
  /** Known social authentications for this user */
  userSocialAuthentications?: Maybe<Array<UserSocialAuthentication>>;
  uuid: Scalars['ID']['output'];
  /** list of help screen names that reflect the help screens the user has viewed completely */
  viewedHelpScreens: Array<Scalars['String']['output']>;
  /** Test Flag for ZPP-enabled App User */
  zppGranted: Scalars['Boolean']['output'];
};

export enum UserRegistrationStatus {
  /** A pending unregistered User */
  Pending = 'PENDING',
  /** A registered User */
  Registered = 'REGISTERED',
  /** An unregistered (device) User */
  Unregistered = 'UNREGISTERED'
}

export enum UserSleepTopics {
  /** Sleep Topic 'choice_five' */
  ChoiceFive = 'CHOICE_FIVE',
  /** Sleep Topic 'choice_four' */
  ChoiceFour = 'CHOICE_FOUR',
  /** Sleep Topic 'choice_one' */
  ChoiceOne = 'CHOICE_ONE',
  /** Sleep Topic 'choice_six' */
  ChoiceSix = 'CHOICE_SIX',
  /** Sleep Topic 'choice_three' */
  ChoiceThree = 'CHOICE_THREE',
  /** Sleep Topic 'choice_two' */
  ChoiceTwo = 'CHOICE_TWO'
}

export type UserSocialAuthentication = {
  __typename?: 'UserSocialAuthentication';
  /**
   *           The email associated to this authentication.
   *
   *           As some authentications don't require an email (Facebook), or allow the user to not to share it (Apple)
   *           this field might be null.
   *
   */
  email?: Maybe<Scalars['String']['output']>;
  /** The authentication name */
  name: UserSocialAuthenticationName;
  uuid: Scalars['ID']['output'];
};

export enum UserSocialAuthenticationName {
  Apple = 'APPLE',
  Facebook = 'FACEBOOK',
  Google = 'GOOGLE'
}

/** A VoucherClient that is associated with vouchers */
export type VoucherClient = {
  __typename?: 'VoucherClient';
  /** Client's logo URL */
  logoUrl?: Maybe<Scalars['String']['output']>;
  /** The name of the client */
  name: Scalars['String']['output'];
  uuid: Scalars['ID']['output'];
};

export type WakeMode = {
  __typename?: 'WakeMode';
  entryType: EntryType;
  groupUnit: GroupUnit;
  training: GroupUnit;
  uuid: Scalars['ID']['output'];
};

export enum WellBeing {
  /** Entity available when condition 'bad' is met */
  Bad = 'BAD',
  /** Entity available when condition 'good' is met */
  Good = 'GOOD',
  /** Entity available when condition 'lousy' is met */
  Lousy = 'LOUSY',
  /** Entity available when condition 'okay' is met */
  Okay = 'OKAY',
  /** Entity available when condition 'super' is met */
  Super = 'SUPER'
}

export type RequestCancellationMutationVariables = Exact<{
  cancellationType: Cancellation;
  product: Product;
  cancellationDateType?: InputMaybe<CancellationDate>;
  requestedCancellationAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  email: Scalars['String']['input'];
  cancellationReason?: InputMaybe<Scalars['String']['input']>;
}>;


export type RequestCancellationMutation = { __typename?: 'Mutation', requestCancellation?: { __typename?: 'CancellationRequest', uuid: string, receivedDate: any, email: string, confirmationPdfUrl: string } | null };

export type AttendInsuranceCooperationMutationVariables = Exact<{
  voucherId: Scalars['String']['input'];
  insuranceNumber?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['ISO8601Date']['input']>;
  gender?: InputMaybe<Gender>;
  age?: InputMaybe<Scalars['Int']['input']>;
}>;


export type AttendInsuranceCooperationMutation = { __typename?: 'Mutation', attendInsuranceCooperation?: { __typename?: 'AttendInsuranceCooperationMutationPayload', user?: { __typename?: 'User', uuid: string } | null } | null };

export type SignInUserWithOnetimePasswordMutationVariables = Exact<{
  token: Scalars['String']['input'];
  email: Scalars['String']['input'];
}>;


export type SignInUserWithOnetimePasswordMutation = { __typename?: 'Mutation', signInUserWithOnetimePassword?: { __typename?: 'SignInPayload', token: string, user: { __typename?: 'User', uuid: string } } | null };

export type SignInWebUserMutationVariables = Exact<{
  token: Scalars['String']['input'];
  email: Scalars['String']['input'];
}>;


export type SignInWebUserMutation = { __typename?: 'Mutation', signInWebUser?: { __typename?: 'SignInPayload', token: string, user: { __typename?: 'User', uuid: string } } | null };

export type ValidateInsuranceNumberMutationVariables = Exact<{
  insuranceNumber: Scalars['String']['input'];
  email: Scalars['String']['input'];
}>;


export type ValidateInsuranceNumberMutation = { __typename?: 'Mutation', validateInsuranceNumber?: boolean | null };

export type PurchaseStripeOfferMutationVariables = Exact<{
  offerId: Scalars['String']['input'];
  paymentMethod: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  email: Scalars['String']['input'];
  couponCode: Scalars['String']['input'];
}>;


export type PurchaseStripeOfferMutation = { __typename?: 'Mutation', purchaseStripeOffer?: { __typename?: 'PurchaseStripeOfferMutationPayload', purchaseToken?: string | null, userAutoAssigned: boolean, isAlreadyPaying?: boolean | null } | null };

export type ResendPurchaseTokenMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type ResendPurchaseTokenMutation = { __typename?: 'Mutation', resendPurchaseToken?: { __typename?: 'ResendPurchaseTokenMutationPayload', status: string } | null };

export type RegisterUserWithOnetimePasswordMutationVariables = Exact<{
  nickname: Scalars['String']['input'];
  email: Scalars['String']['input'];
  termsAccepted: Scalars['Boolean']['input'];
}>;


export type RegisterUserWithOnetimePasswordMutation = { __typename?: 'Mutation', registerUserWithOnetimePassword?: { __typename?: 'RegisterPayload', user: { __typename?: 'User', uuid: string, email?: string | null } } | null };

export type RegisterWebUserMutationVariables = Exact<{
  nickname: Scalars['String']['input'];
  email: Scalars['String']['input'];
  termsAccepted: Scalars['Boolean']['input'];
}>;


export type RegisterWebUserMutation = { __typename?: 'Mutation', registerWebUser?: { __typename?: 'RegisterWebUserMutationPayload', result?: boolean | null } | null };

export type RedeemVoucherMutationVariables = Exact<{
  code: Scalars['String']['input'];
  email: Scalars['String']['input'];
}>;


export type RedeemVoucherMutation = { __typename?: 'Mutation', redeemVoucher?: { __typename?: 'RedeemVoucherMutationPayload', token?: string | null, voucherClient?: { __typename?: 'VoucherClient', name: string, logoUrl?: string | null } | null } | null };

export type MoneyFragmentFragment = { __typename?: 'Money', cents: number, currency: string, localizedPrice: string, uuid: string };

export type SinglePaymentOfferFragmentFragment = { __typename: 'SinglePaymentOffer', uuid: string, zppCertified: boolean, countryCode?: OfferCountryCode | null, groupName: string, name: string, taxRatePercentage?: number | null, taxInclusive: boolean, position: number, highlight: boolean, provider: OfferProvider, providerId: string, purchased?: boolean | null, crossedOutPrice?: { __typename?: 'Money', cents: number, currency: string, localizedPrice: string, uuid: string } | null, coupon?: { __typename?: 'StripeCoupon', name: string, amountOff?: number | null, duration: string, durationInMonths?: number | null } | null, price: { __typename?: 'Money', cents: number, currency: string, localizedPrice: string, uuid: string } };

export type StripeCardPaymentMethodFragmentFragment = { __typename?: 'StripeCardPaymentMethod', id: string, type: string, uuid: string, card: { __typename?: 'StripeCard', brand: string, last4: string, expMonth: string, expYear: string, country?: string | null } };

export type StripeSepaDebitPaymentMethodFragmentFragment = { __typename?: 'StripeSepaDebitPaymentMethod', id: string, type: string, uuid: string, sepaDebit: { __typename?: 'StripeSepaDebit', bankCode?: string | null, last4: string, country?: string | null } };

export type SubscriptionOfferFragmentFragment = { __typename: 'SubscriptionOffer', uuid: string, zppCertified: boolean, countryCode?: OfferCountryCode | null, groupName: string, name: string, taxRatePercentage?: number | null, taxInclusive: boolean, interval: SubscriptionOfferInterval, position: number, highlight: boolean, provider: OfferProvider, providerId: string, purchased?: boolean | null, crossedOutPrice?: { __typename?: 'Money', cents: number, currency: string, localizedPrice: string, uuid: string } | null, coupon?: { __typename?: 'StripeCoupon', name: string, amountOff?: number | null, duration: string, durationInMonths?: number | null } | null, price: { __typename?: 'Money', cents: number, currency: string, localizedPrice: string, uuid: string } };

export type OffersQueryVariables = Exact<{
  provider?: InputMaybe<OfferProvider>;
  couponCode?: InputMaybe<Scalars['String']['input']>;
  groupName?: InputMaybe<Scalars['String']['input']>;
}>;


export type OffersQuery = { __typename?: 'Query', offers: Array<{ __typename: 'SinglePaymentOffer', uuid: string, zppCertified: boolean, countryCode?: OfferCountryCode | null, groupName: string, name: string, taxRatePercentage?: number | null, taxInclusive: boolean, position: number, highlight: boolean, provider: OfferProvider, providerId: string, purchased?: boolean | null, crossedOutPrice?: { __typename?: 'Money', cents: number, currency: string, localizedPrice: string, uuid: string } | null, coupon?: { __typename?: 'StripeCoupon', name: string, amountOff?: number | null, duration: string, durationInMonths?: number | null } | null, price: { __typename?: 'Money', cents: number, currency: string, localizedPrice: string, uuid: string } } | { __typename: 'SubscriptionOffer', uuid: string, zppCertified: boolean, countryCode?: OfferCountryCode | null, groupName: string, name: string, taxRatePercentage?: number | null, taxInclusive: boolean, interval: SubscriptionOfferInterval, position: number, highlight: boolean, provider: OfferProvider, providerId: string, purchased?: boolean | null, crossedOutPrice?: { __typename?: 'Money', cents: number, currency: string, localizedPrice: string, uuid: string } | null, coupon?: { __typename?: 'StripeCoupon', name: string, amountOff?: number | null, duration: string, durationInMonths?: number | null } | null, price: { __typename?: 'Money', cents: number, currency: string, localizedPrice: string, uuid: string } }> };

export type StripePaymentMethodQueryVariables = Exact<{
  paymentMethodId: Scalars['String']['input'];
}>;


export type StripePaymentMethodQuery = { __typename?: 'Query', stripePaymentMethod: { __typename: 'StripeCardPaymentMethod', id: string, type: string, uuid: string, card: { __typename?: 'StripeCard', brand: string, last4: string, expMonth: string, expYear: string, country?: string | null } } | { __typename: 'StripeSepaDebitPaymentMethod', id: string, type: string, uuid: string, sepaDebit: { __typename?: 'StripeSepaDebit', bankCode?: string | null, last4: string, country?: string | null } } };

export type StripeSetupIntentQueryVariables = Exact<{ [key: string]: never; }>;


export type StripeSetupIntentQuery = { __typename?: 'Query', stripeSetupIntent: { __typename?: 'StripeSetupIntent', id: string, clientSecret: string } };

export type StaticPagesQueryVariables = Exact<{
  name: StaticPageName;
}>;


export type StaticPagesQuery = { __typename?: 'Query', staticPages: Array<{ __typename?: 'StaticPage', markdown: string }> };

export const MoneyFragmentFragmentDoc = gql`
    fragment moneyFragment on Money {
  cents
  currency
  localizedPrice
  uuid
}
    `;
export const SinglePaymentOfferFragmentFragmentDoc = gql`
    fragment singlePaymentOfferFragment on SinglePaymentOffer {
  uuid
  zppCertified
  countryCode
  crossedOutPrice {
    ...moneyFragment
  }
  coupon {
    name
    amountOff
    duration
    durationInMonths
  }
  groupName
  name
  price {
    ...moneyFragment
  }
  taxRatePercentage
  taxInclusive
  position
  highlight
  provider
  providerId
  purchased
  uuid
  __typename
}
    ${MoneyFragmentFragmentDoc}`;
export const StripeCardPaymentMethodFragmentFragmentDoc = gql`
    fragment stripeCardPaymentMethodFragment on StripeCardPaymentMethod {
  id
  type
  uuid
  card {
    brand
    last4
    expMonth
    expYear
    country
  }
}
    `;
export const StripeSepaDebitPaymentMethodFragmentFragmentDoc = gql`
    fragment stripeSepaDebitPaymentMethodFragment on StripeSepaDebitPaymentMethod {
  id
  type
  uuid
  sepaDebit {
    bankCode
    last4
    country
  }
}
    `;
export const SubscriptionOfferFragmentFragmentDoc = gql`
    fragment subscriptionOfferFragment on SubscriptionOffer {
  uuid
  zppCertified
  countryCode
  crossedOutPrice {
    ...moneyFragment
  }
  coupon {
    name
    amountOff
    duration
    durationInMonths
  }
  groupName
  name
  price {
    ...moneyFragment
  }
  taxRatePercentage
  taxInclusive
  interval
  position
  highlight
  provider
  providerId
  purchased
  uuid
  __typename
}
    ${MoneyFragmentFragmentDoc}`;
export const RequestCancellationDocument = gql`
    mutation RequestCancellation($cancellationType: Cancellation!, $product: Product!, $cancellationDateType: CancellationDate, $requestedCancellationAt: ISO8601DateTime, $email: String!, $cancellationReason: String) {
  requestCancellation(
    cancellationType: $cancellationType
    product: $product
    cancellationDateType: $cancellationDateType
    requestedCancellationAt: $requestedCancellationAt
    email: $email
    cancellationReason: $cancellationReason
  ) {
    uuid
    receivedDate
    email
    confirmationPdfUrl
  }
}
    `;
export type RequestCancellationMutationFn = Apollo.MutationFunction<RequestCancellationMutation, RequestCancellationMutationVariables>;

/**
 * __useRequestCancellationMutation__
 *
 * To run a mutation, you first call `useRequestCancellationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestCancellationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestCancellationMutation, { data, loading, error }] = useRequestCancellationMutation({
 *   variables: {
 *      cancellationType: // value for 'cancellationType'
 *      product: // value for 'product'
 *      cancellationDateType: // value for 'cancellationDateType'
 *      requestedCancellationAt: // value for 'requestedCancellationAt'
 *      email: // value for 'email'
 *      cancellationReason: // value for 'cancellationReason'
 *   },
 * });
 */
export function useRequestCancellationMutation(baseOptions?: Apollo.MutationHookOptions<RequestCancellationMutation, RequestCancellationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestCancellationMutation, RequestCancellationMutationVariables>(RequestCancellationDocument, options);
      }
export type RequestCancellationMutationHookResult = ReturnType<typeof useRequestCancellationMutation>;
export type RequestCancellationMutationResult = Apollo.MutationResult<RequestCancellationMutation>;
export type RequestCancellationMutationOptions = Apollo.BaseMutationOptions<RequestCancellationMutation, RequestCancellationMutationVariables>;
export const AttendInsuranceCooperationDocument = gql`
    mutation AttendInsuranceCooperation($voucherId: String!, $insuranceNumber: String, $companyName: String, $dateOfBirth: ISO8601Date, $gender: Gender, $age: Int) {
  attendInsuranceCooperation(
    voucherId: $voucherId
    insuranceNumber: $insuranceNumber
    companyName: $companyName
    dateOfBirth: $dateOfBirth
    gender: $gender
    age: $age
  ) {
    user {
      uuid
    }
  }
}
    `;
export type AttendInsuranceCooperationMutationFn = Apollo.MutationFunction<AttendInsuranceCooperationMutation, AttendInsuranceCooperationMutationVariables>;

/**
 * __useAttendInsuranceCooperationMutation__
 *
 * To run a mutation, you first call `useAttendInsuranceCooperationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttendInsuranceCooperationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attendInsuranceCooperationMutation, { data, loading, error }] = useAttendInsuranceCooperationMutation({
 *   variables: {
 *      voucherId: // value for 'voucherId'
 *      insuranceNumber: // value for 'insuranceNumber'
 *      companyName: // value for 'companyName'
 *      dateOfBirth: // value for 'dateOfBirth'
 *      gender: // value for 'gender'
 *      age: // value for 'age'
 *   },
 * });
 */
export function useAttendInsuranceCooperationMutation(baseOptions?: Apollo.MutationHookOptions<AttendInsuranceCooperationMutation, AttendInsuranceCooperationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AttendInsuranceCooperationMutation, AttendInsuranceCooperationMutationVariables>(AttendInsuranceCooperationDocument, options);
      }
export type AttendInsuranceCooperationMutationHookResult = ReturnType<typeof useAttendInsuranceCooperationMutation>;
export type AttendInsuranceCooperationMutationResult = Apollo.MutationResult<AttendInsuranceCooperationMutation>;
export type AttendInsuranceCooperationMutationOptions = Apollo.BaseMutationOptions<AttendInsuranceCooperationMutation, AttendInsuranceCooperationMutationVariables>;
export const SignInUserWithOnetimePasswordDocument = gql`
    mutation SignInUserWithOnetimePassword($token: String!, $email: String!) {
  signInUserWithOnetimePassword(token: $token, email: $email) {
    token
    user {
      uuid
    }
  }
}
    `;
export type SignInUserWithOnetimePasswordMutationFn = Apollo.MutationFunction<SignInUserWithOnetimePasswordMutation, SignInUserWithOnetimePasswordMutationVariables>;

/**
 * __useSignInUserWithOnetimePasswordMutation__
 *
 * To run a mutation, you first call `useSignInUserWithOnetimePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInUserWithOnetimePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInUserWithOnetimePasswordMutation, { data, loading, error }] = useSignInUserWithOnetimePasswordMutation({
 *   variables: {
 *      token: // value for 'token'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSignInUserWithOnetimePasswordMutation(baseOptions?: Apollo.MutationHookOptions<SignInUserWithOnetimePasswordMutation, SignInUserWithOnetimePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignInUserWithOnetimePasswordMutation, SignInUserWithOnetimePasswordMutationVariables>(SignInUserWithOnetimePasswordDocument, options);
      }
export type SignInUserWithOnetimePasswordMutationHookResult = ReturnType<typeof useSignInUserWithOnetimePasswordMutation>;
export type SignInUserWithOnetimePasswordMutationResult = Apollo.MutationResult<SignInUserWithOnetimePasswordMutation>;
export type SignInUserWithOnetimePasswordMutationOptions = Apollo.BaseMutationOptions<SignInUserWithOnetimePasswordMutation, SignInUserWithOnetimePasswordMutationVariables>;
export const SignInWebUserDocument = gql`
    mutation SignInWebUser($token: String!, $email: String!) {
  signInWebUser(token: $token, email: $email) {
    token
    user {
      uuid
    }
  }
}
    `;
export type SignInWebUserMutationFn = Apollo.MutationFunction<SignInWebUserMutation, SignInWebUserMutationVariables>;

/**
 * __useSignInWebUserMutation__
 *
 * To run a mutation, you first call `useSignInWebUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInWebUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInWebUserMutation, { data, loading, error }] = useSignInWebUserMutation({
 *   variables: {
 *      token: // value for 'token'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSignInWebUserMutation(baseOptions?: Apollo.MutationHookOptions<SignInWebUserMutation, SignInWebUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignInWebUserMutation, SignInWebUserMutationVariables>(SignInWebUserDocument, options);
      }
export type SignInWebUserMutationHookResult = ReturnType<typeof useSignInWebUserMutation>;
export type SignInWebUserMutationResult = Apollo.MutationResult<SignInWebUserMutation>;
export type SignInWebUserMutationOptions = Apollo.BaseMutationOptions<SignInWebUserMutation, SignInWebUserMutationVariables>;
export const ValidateInsuranceNumberDocument = gql`
    mutation ValidateInsuranceNumber($insuranceNumber: String!, $email: String!) {
  validateInsuranceNumber(insuranceNumber: $insuranceNumber, email: $email)
}
    `;
export type ValidateInsuranceNumberMutationFn = Apollo.MutationFunction<ValidateInsuranceNumberMutation, ValidateInsuranceNumberMutationVariables>;

/**
 * __useValidateInsuranceNumberMutation__
 *
 * To run a mutation, you first call `useValidateInsuranceNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateInsuranceNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateInsuranceNumberMutation, { data, loading, error }] = useValidateInsuranceNumberMutation({
 *   variables: {
 *      insuranceNumber: // value for 'insuranceNumber'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useValidateInsuranceNumberMutation(baseOptions?: Apollo.MutationHookOptions<ValidateInsuranceNumberMutation, ValidateInsuranceNumberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ValidateInsuranceNumberMutation, ValidateInsuranceNumberMutationVariables>(ValidateInsuranceNumberDocument, options);
      }
export type ValidateInsuranceNumberMutationHookResult = ReturnType<typeof useValidateInsuranceNumberMutation>;
export type ValidateInsuranceNumberMutationResult = Apollo.MutationResult<ValidateInsuranceNumberMutation>;
export type ValidateInsuranceNumberMutationOptions = Apollo.BaseMutationOptions<ValidateInsuranceNumberMutation, ValidateInsuranceNumberMutationVariables>;
export const PurchaseStripeOfferDocument = gql`
    mutation PurchaseStripeOffer($offerId: String!, $paymentMethod: String!, $firstName: String!, $lastName: String!, $email: String!, $couponCode: String!) {
  purchaseStripeOffer(
    offerId: $offerId
    paymentMethod: $paymentMethod
    firstName: $firstName
    lastName: $lastName
    email: $email
    couponCode: $couponCode
  ) {
    purchaseToken
    userAutoAssigned
    isAlreadyPaying
  }
}
    `;
export type PurchaseStripeOfferMutationFn = Apollo.MutationFunction<PurchaseStripeOfferMutation, PurchaseStripeOfferMutationVariables>;

/**
 * __usePurchaseStripeOfferMutation__
 *
 * To run a mutation, you first call `usePurchaseStripeOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePurchaseStripeOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [purchaseStripeOfferMutation, { data, loading, error }] = usePurchaseStripeOfferMutation({
 *   variables: {
 *      offerId: // value for 'offerId'
 *      paymentMethod: // value for 'paymentMethod'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      couponCode: // value for 'couponCode'
 *   },
 * });
 */
export function usePurchaseStripeOfferMutation(baseOptions?: Apollo.MutationHookOptions<PurchaseStripeOfferMutation, PurchaseStripeOfferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PurchaseStripeOfferMutation, PurchaseStripeOfferMutationVariables>(PurchaseStripeOfferDocument, options);
      }
export type PurchaseStripeOfferMutationHookResult = ReturnType<typeof usePurchaseStripeOfferMutation>;
export type PurchaseStripeOfferMutationResult = Apollo.MutationResult<PurchaseStripeOfferMutation>;
export type PurchaseStripeOfferMutationOptions = Apollo.BaseMutationOptions<PurchaseStripeOfferMutation, PurchaseStripeOfferMutationVariables>;
export const ResendPurchaseTokenDocument = gql`
    mutation ResendPurchaseToken($email: String!) {
  resendPurchaseToken(email: $email) {
    status
  }
}
    `;
export type ResendPurchaseTokenMutationFn = Apollo.MutationFunction<ResendPurchaseTokenMutation, ResendPurchaseTokenMutationVariables>;

/**
 * __useResendPurchaseTokenMutation__
 *
 * To run a mutation, you first call `useResendPurchaseTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendPurchaseTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendPurchaseTokenMutation, { data, loading, error }] = useResendPurchaseTokenMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useResendPurchaseTokenMutation(baseOptions?: Apollo.MutationHookOptions<ResendPurchaseTokenMutation, ResendPurchaseTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendPurchaseTokenMutation, ResendPurchaseTokenMutationVariables>(ResendPurchaseTokenDocument, options);
      }
export type ResendPurchaseTokenMutationHookResult = ReturnType<typeof useResendPurchaseTokenMutation>;
export type ResendPurchaseTokenMutationResult = Apollo.MutationResult<ResendPurchaseTokenMutation>;
export type ResendPurchaseTokenMutationOptions = Apollo.BaseMutationOptions<ResendPurchaseTokenMutation, ResendPurchaseTokenMutationVariables>;
export const RegisterUserWithOnetimePasswordDocument = gql`
    mutation RegisterUserWithOnetimePassword($nickname: String!, $email: String!, $termsAccepted: Boolean!) {
  registerUserWithOnetimePassword(
    nickname: $nickname
    email: $email
    termsAccepted: $termsAccepted
  ) {
    user {
      uuid
      email
    }
  }
}
    `;
export type RegisterUserWithOnetimePasswordMutationFn = Apollo.MutationFunction<RegisterUserWithOnetimePasswordMutation, RegisterUserWithOnetimePasswordMutationVariables>;

/**
 * __useRegisterUserWithOnetimePasswordMutation__
 *
 * To run a mutation, you first call `useRegisterUserWithOnetimePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterUserWithOnetimePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerUserWithOnetimePasswordMutation, { data, loading, error }] = useRegisterUserWithOnetimePasswordMutation({
 *   variables: {
 *      nickname: // value for 'nickname'
 *      email: // value for 'email'
 *      termsAccepted: // value for 'termsAccepted'
 *   },
 * });
 */
export function useRegisterUserWithOnetimePasswordMutation(baseOptions?: Apollo.MutationHookOptions<RegisterUserWithOnetimePasswordMutation, RegisterUserWithOnetimePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterUserWithOnetimePasswordMutation, RegisterUserWithOnetimePasswordMutationVariables>(RegisterUserWithOnetimePasswordDocument, options);
      }
export type RegisterUserWithOnetimePasswordMutationHookResult = ReturnType<typeof useRegisterUserWithOnetimePasswordMutation>;
export type RegisterUserWithOnetimePasswordMutationResult = Apollo.MutationResult<RegisterUserWithOnetimePasswordMutation>;
export type RegisterUserWithOnetimePasswordMutationOptions = Apollo.BaseMutationOptions<RegisterUserWithOnetimePasswordMutation, RegisterUserWithOnetimePasswordMutationVariables>;
export const RegisterWebUserDocument = gql`
    mutation RegisterWebUser($nickname: String!, $email: String!, $termsAccepted: Boolean!) {
  registerWebUser(
    nickname: $nickname
    email: $email
    termsAccepted: $termsAccepted
  ) {
    result
  }
}
    `;
export type RegisterWebUserMutationFn = Apollo.MutationFunction<RegisterWebUserMutation, RegisterWebUserMutationVariables>;

/**
 * __useRegisterWebUserMutation__
 *
 * To run a mutation, you first call `useRegisterWebUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterWebUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerWebUserMutation, { data, loading, error }] = useRegisterWebUserMutation({
 *   variables: {
 *      nickname: // value for 'nickname'
 *      email: // value for 'email'
 *      termsAccepted: // value for 'termsAccepted'
 *   },
 * });
 */
export function useRegisterWebUserMutation(baseOptions?: Apollo.MutationHookOptions<RegisterWebUserMutation, RegisterWebUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterWebUserMutation, RegisterWebUserMutationVariables>(RegisterWebUserDocument, options);
      }
export type RegisterWebUserMutationHookResult = ReturnType<typeof useRegisterWebUserMutation>;
export type RegisterWebUserMutationResult = Apollo.MutationResult<RegisterWebUserMutation>;
export type RegisterWebUserMutationOptions = Apollo.BaseMutationOptions<RegisterWebUserMutation, RegisterWebUserMutationVariables>;
export const RedeemVoucherDocument = gql`
    mutation RedeemVoucher($code: String!, $email: String!) {
  redeemVoucher(code: $code, email: $email) {
    token
    voucherClient {
      name
      logoUrl
    }
  }
}
    `;
export type RedeemVoucherMutationFn = Apollo.MutationFunction<RedeemVoucherMutation, RedeemVoucherMutationVariables>;

/**
 * __useRedeemVoucherMutation__
 *
 * To run a mutation, you first call `useRedeemVoucherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRedeemVoucherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [redeemVoucherMutation, { data, loading, error }] = useRedeemVoucherMutation({
 *   variables: {
 *      code: // value for 'code'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRedeemVoucherMutation(baseOptions?: Apollo.MutationHookOptions<RedeemVoucherMutation, RedeemVoucherMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RedeemVoucherMutation, RedeemVoucherMutationVariables>(RedeemVoucherDocument, options);
      }
export type RedeemVoucherMutationHookResult = ReturnType<typeof useRedeemVoucherMutation>;
export type RedeemVoucherMutationResult = Apollo.MutationResult<RedeemVoucherMutation>;
export type RedeemVoucherMutationOptions = Apollo.BaseMutationOptions<RedeemVoucherMutation, RedeemVoucherMutationVariables>;
export const OffersDocument = gql`
    query Offers($provider: OfferProvider, $couponCode: String, $groupName: String) {
  offers(provider: $provider, couponCode: $couponCode, groupName: $groupName) {
    ... on SinglePaymentOffer {
      ...singlePaymentOfferFragment
    }
    ... on SubscriptionOffer {
      ...subscriptionOfferFragment
    }
  }
}
    ${SinglePaymentOfferFragmentFragmentDoc}
${SubscriptionOfferFragmentFragmentDoc}`;

/**
 * __useOffersQuery__
 *
 * To run a query within a React component, call `useOffersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOffersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOffersQuery({
 *   variables: {
 *      provider: // value for 'provider'
 *      couponCode: // value for 'couponCode'
 *      groupName: // value for 'groupName'
 *   },
 * });
 */
export function useOffersQuery(baseOptions?: Apollo.QueryHookOptions<OffersQuery, OffersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OffersQuery, OffersQueryVariables>(OffersDocument, options);
      }
export function useOffersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OffersQuery, OffersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OffersQuery, OffersQueryVariables>(OffersDocument, options);
        }
export type OffersQueryHookResult = ReturnType<typeof useOffersQuery>;
export type OffersLazyQueryHookResult = ReturnType<typeof useOffersLazyQuery>;
export type OffersQueryResult = Apollo.QueryResult<OffersQuery, OffersQueryVariables>;
export const StripePaymentMethodDocument = gql`
    query StripePaymentMethod($paymentMethodId: String!) {
  stripePaymentMethod(paymentMethodId: $paymentMethodId) {
    __typename
    ... on StripeCardPaymentMethod {
      ...stripeCardPaymentMethodFragment
    }
    ... on StripeSepaDebitPaymentMethod {
      ...stripeSepaDebitPaymentMethodFragment
    }
  }
}
    ${StripeCardPaymentMethodFragmentFragmentDoc}
${StripeSepaDebitPaymentMethodFragmentFragmentDoc}`;

/**
 * __useStripePaymentMethodQuery__
 *
 * To run a query within a React component, call `useStripePaymentMethodQuery` and pass it any options that fit your needs.
 * When your component renders, `useStripePaymentMethodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStripePaymentMethodQuery({
 *   variables: {
 *      paymentMethodId: // value for 'paymentMethodId'
 *   },
 * });
 */
export function useStripePaymentMethodQuery(baseOptions: Apollo.QueryHookOptions<StripePaymentMethodQuery, StripePaymentMethodQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StripePaymentMethodQuery, StripePaymentMethodQueryVariables>(StripePaymentMethodDocument, options);
      }
export function useStripePaymentMethodLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StripePaymentMethodQuery, StripePaymentMethodQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StripePaymentMethodQuery, StripePaymentMethodQueryVariables>(StripePaymentMethodDocument, options);
        }
export type StripePaymentMethodQueryHookResult = ReturnType<typeof useStripePaymentMethodQuery>;
export type StripePaymentMethodLazyQueryHookResult = ReturnType<typeof useStripePaymentMethodLazyQuery>;
export type StripePaymentMethodQueryResult = Apollo.QueryResult<StripePaymentMethodQuery, StripePaymentMethodQueryVariables>;
export const StripeSetupIntentDocument = gql`
    query StripeSetupIntent {
  stripeSetupIntent {
    id
    clientSecret
  }
}
    `;

/**
 * __useStripeSetupIntentQuery__
 *
 * To run a query within a React component, call `useStripeSetupIntentQuery` and pass it any options that fit your needs.
 * When your component renders, `useStripeSetupIntentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStripeSetupIntentQuery({
 *   variables: {
 *   },
 * });
 */
export function useStripeSetupIntentQuery(baseOptions?: Apollo.QueryHookOptions<StripeSetupIntentQuery, StripeSetupIntentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StripeSetupIntentQuery, StripeSetupIntentQueryVariables>(StripeSetupIntentDocument, options);
      }
export function useStripeSetupIntentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StripeSetupIntentQuery, StripeSetupIntentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StripeSetupIntentQuery, StripeSetupIntentQueryVariables>(StripeSetupIntentDocument, options);
        }
export type StripeSetupIntentQueryHookResult = ReturnType<typeof useStripeSetupIntentQuery>;
export type StripeSetupIntentLazyQueryHookResult = ReturnType<typeof useStripeSetupIntentLazyQuery>;
export type StripeSetupIntentQueryResult = Apollo.QueryResult<StripeSetupIntentQuery, StripeSetupIntentQueryVariables>;
export const StaticPagesDocument = gql`
    query StaticPages($name: StaticPageName!) {
  staticPages(name: $name) {
    markdown
  }
}
    `;

/**
 * __useStaticPagesQuery__
 *
 * To run a query within a React component, call `useStaticPagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaticPagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaticPagesQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useStaticPagesQuery(baseOptions: Apollo.QueryHookOptions<StaticPagesQuery, StaticPagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StaticPagesQuery, StaticPagesQueryVariables>(StaticPagesDocument, options);
      }
export function useStaticPagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StaticPagesQuery, StaticPagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StaticPagesQuery, StaticPagesQueryVariables>(StaticPagesDocument, options);
        }
export type StaticPagesQueryHookResult = ReturnType<typeof useStaticPagesQuery>;
export type StaticPagesLazyQueryHookResult = ReturnType<typeof useStaticPagesLazyQuery>;
export type StaticPagesQueryResult = Apollo.QueryResult<StaticPagesQuery, StaticPagesQueryVariables>;