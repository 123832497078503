import React, { MouseEvent, forwardRef, FunctionComponent, useState } from 'react';
import { Button, Caption, Col, Headline, Image, Paragraph, Row, Sup, Switch } from 'View/Common';
import {
  AccessibilityModal,
  DarkContentBox,
  HealthInsuranceSelectionBox,
  HealthRequirementsModal,
  IHealthInsuranceEntry,
  Section
} from 'View/Component';
import ZppShipBackground from 'img/ZppShipBackground';
import { useGetOffers } from 'View/HookApi/useGetOffers';
import { LoadingOutlined } from '@ant-design/icons';
import { useSelectOffer } from 'View/HookApi/useSelectOffer';
import { MarkdownModal } from 'View/Component/Modals/MarkdownModal/MarkdownModal';
import { StaticPageName } from 'api/graphql';

interface IProps {
  ref?: React.LegacyRef<HTMLElement>;
}
export const RefundCheckFormSection: FunctionComponent<IProps> = forwardRef((_, ref) => {
  const [healthInsurance, setHealthInsurance] = useState<IHealthInsuranceEntry | null>(null);
  const [showHealthRequirements, setShowHealthRequirements] = useState<boolean>(false);
  const [showAccessibilityModal, setShowAccessibilityModal] = useState<boolean>(false);
  const [showTerms, setShowTerms] = useState<boolean>(false);
  const [showPrivacy, setShowPrivacy] = useState<boolean>(false);
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false);
  const [privacyAccepted, setPrivacyAccepted] = useState<boolean>(false);
  const [showTermsError, setShowTermsError] = useState<boolean>(false);
  const [showPrivacyError, setShowPrivacyError] = useState<boolean>(false);

  const { loading, offers } = useGetOffers();
  const { selectOffer } = useSelectOffer();

  const handleOnSetPrivacy = (privacyAccepted: boolean) => {
    setPrivacyAccepted(privacyAccepted);
    setShowPrivacyError(false);
  };

  const handleOnSetTerms = (termsAccepted: boolean) => {
    setTermsAccepted(termsAccepted);
    setShowTermsError(false);
  };

  const handleOnOpenZppOffer = (evt: MouseEvent) => {
    if (!offers.zpp) {
      return;
    }
    if (!termsAccepted || !privacyAccepted) {
      if (!termsAccepted) {
        setShowTermsError(true);
      }
      if (!privacyAccepted) {
        setShowPrivacyError(true);
      }
      return;
    }

    selectOffer(evt, offers.zpp);
  };

  return (
    <>
      <HealthRequirementsModal
        open={showHealthRequirements}
        onClose={() => setShowHealthRequirements(false)}
      />
      <AccessibilityModal
        open={showAccessibilityModal}
        onClose={() => setShowAccessibilityModal(false)}
      />
      <MarkdownModal open={showTerms} onClose={() => setShowTerms(false)} pageName={StaticPageName.TermsAndConditions}/>
      <MarkdownModal open={showPrivacy} onClose={() => setShowPrivacy(false)} pageName={StaticPageName.PrivacyPolicy}/>
      <Section className="refund-check-form-section" ref={ref}>
        <Image
          className="app-section-background"
          image={ZppShipBackground.src}
          srcSet={ZppShipBackground.srcSet}
          alt="Papierboot mit Angelleine"
        />
        <Row gutter={16}>
          <Col xs={24} sm={12} md={12} className="form-area">
            <HealthInsuranceSelectionBox
              healthInsurance={healthInsurance}
              onChange={setHealthInsurance}
            />
          </Col>
          <Col xs={24} sm={12} md={{ span: 9, offset: 3 }} className="price-area">
            <div className="flex">
              <DarkContentBox className="content-form-box center">
                <Headline level={4}>Präventionskurs</Headline>
                <Caption>inkl. 12 Monate 7Schläfer</Caption>
                <Paragraph className="price-field">
                  {offers.zpp?.price?.localizedPrice || <LoadingOutlined />}
                </Paragraph>
                <Caption>
                  einmalig<Sup>*</Sup>
                </Caption>
                <br />
                <br />
                <Row gutter={16}>
                  <Col span={6}>
                    <Switch
                      title="Gesundheitsvoraussetzungen bestätigen"
                      onChange={handleOnSetPrivacy}
                    />
                  </Col>
                  <Col span={18} className="text-left">
                    <Caption>
                      Ich akzeptiere die{' '}
                      <Button
                        title="öffnet ein Fenster mit dem gesundheitlichen Voraussetzungen"
                        type="link"
                        onClick={() => setShowHealthRequirements(true)}
                      >
                        Gesundheits&shy;voraussetzungen
                      </Button>{' '}
                      und bestätige hiermit, dass keine Kontraindikationen für die Absolvierung des
                      Kurses vorliegen.
                      {showPrivacyError && (
                        <span className="error">
                          Bitte akzeptiere die Gesundheitsvoraussetzungen.
                        </span>
                      )}
                    </Caption>
                  </Col>
                </Row>
                <br />
                <Row gutter={16}>
                  <Col span={6}>
                    <Switch title="AGB und Datenschutz bestätigen" onChange={handleOnSetTerms} />
                  </Col>
                  <Col span={18} className="text-left">
                    <Caption dataCyName="terms-privacy-caption">
                      Ich akzeptiere die{' '}
                      <Button
                        title="öffnet ein Fenster mit den AGBs"
                        type="link"
                        onClick={() => setShowTerms(true)}
                      >
                        AGB
                      </Button>{' '}
                      und die Hinweise zum{' '}
                      <Button
                        title="öffnet ein Fenster mit den Datenschutzbedingungen"
                        type="link"
                        onClick={() => setShowPrivacy(true)}
                      >
                        Datenschutz
                      </Button>
                      .
                      {showTermsError && (
                        <span className="error">Bitte akzeptiere die AGB und Datenschutz.</span>
                      )}
                    </Caption>
                  </Col>
                </Row>
                <br />
                <Button
                  title="Präventionskurs kaufen"
                  onClick={handleOnOpenZppOffer}
                  className="buy-button"
                  disabled={loading}
                >
                  {loading && <LoadingOutlined />}
                  Jetzt kaufen
                </Button>
                <br />
                <Caption small className="info-text">
                  * Freischaltung endet automatisch nach einem Jahr und wird nicht verlängert.
                  Erfordert ein Smartphone mit Android 8.0 bzw. iOS 13.0 oder höher. Alle
                  gesetzlichen Regelungen zum Einsatz des digitalen Präventions- bzw.
                  Gesundheitsförderungsangebots sind berücksichtigt. Zu den{' '}
                  <Button
                    title="öffnet ein Fenster mit den Angaben zur Barrierefreiheit"
                    type="link"
                    onClick={() => setShowAccessibilityModal(true)}
                  >
                    Angaben zur Barrierefreiheit
                  </Button>
                </Caption>
              </DarkContentBox>
            </div>
          </Col>
        </Row>
      </Section>
    </>
  );
});
