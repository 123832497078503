import styled from 'styled-components';
import { asRem } from 'utils/asRem';
import { mediaQueries } from 'utils/Constants/Breakpoints';
import bgImageMobile from 'img/bgr_waves_mobile.svg';
import bgImage from 'img/bgr_waves.svg';
import logo from 'img/7schlaefer-logo.svg';
import { breakpoints, colors } from 'View/Theme';

export default styled.main.attrs({ className: 'landingpage' })`
  padding-left: 0;
  padding-right: 0;
  position: relative;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  background-image: url(${bgImageMobile});
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  overflow: hidden;
  color: ${colors.white.c500};
  
  ${mediaQueries.md} {
    background-image: url(${bgImage});
  }
  

  h2 {
    color: ${colors.sleep.beige.c100};
  }

  .mime-elements-section {
    .container {
      max-width: ${asRem(944)};
    }
    
    &[type="full-width"] .container {
      max-width: 100%;
    }
  }

  .navbar-sleep-brand, .menu-story-container .navbar-transparent.navbar-down .navbar-sleep-brand {
    background-image: url(${logo});
  }

  .navbar-transparent .navbar-sleep-brand {
    background-image: url(${logo});
  }

  .navbar-down {
    background-color: var(--sleep-blue-c700) !important;
    box-shadow: none !important;
  }
  
  .paragraph-section {
    .mime-elements-paragraph {
      padding: ${asRem(24)};
    }
  }

  .intro-form-paragraph > a {
    font-weight: 600;
  }

  .go-app-button {
    width: ${asRem(213)};
    margin-right: auto;
    margin-left: auto;
    margin-bottom: var(--sleep-gutter-s) !important;
  }
  
  .mime-elements-toggle p {
    margin-left: ${asRem(4)};
    margin-top: ${asRem(8)};
    margin-bottom: ${asRem(4)};
    font-size: 14px;
    font-weight: 400;
  }

  .form-button {
    display: flex;
    justify-content: center;
    margin-top: var(--sleep-gutter-l);
    margin-bottom: var(--sleep-gutter-xl);
  }

  .form-insurance-number-subtext {
    margin-top: ${asRem(2)};
    padding-top: ${asRem(2)};
    font-size: ${asRem(14)};
  }
  

  .legal-newsletter-info {
    border-top:  ${asRem(1)} solid var(--sleep-blue-c200);
    padding: ${asRem(24)} ${asRem(4)} 0 ${asRem(4)};
    margin-top: ${asRem(32)};
  }

  .paragraph-section {
    .text-block-img {
      margin-bottom: 0;
    }

    .mime-elements-paragraph {

      padding: 0 ${ asRem( 24 ) };
      margin: 0;

      @media (min-width: ${breakpoints.md}) {
        padding: ${ asRem( 24 ) };

      }

    }
  }

  .form-view {
    margin: 0 auto;
    max-width: ${ asRem( 640 ) };
    display: block;
  }

  .form-section {
    padding: 0;
    background-color: var(--sleep-blue-c770);
    
    ${mediaQueries.md} {
      background-color: transparent;
    }
  }

  .mail-link {
      font-family: SourceSansPro-Regular;
      text-decoration: underline;
    }

  .form-regulatory-link {
    font-family: SourceSansPro-Regular;
    font-size: ${asRem(14)};
  }

  footer {
    width: 100%;  
  }
`;
