import React, { FC, ReactNode } from 'react';
import Style from './InsuranceLandingPage.style';
import {
  Menu, NavbarBrand,
  NavLink,
} from '@missionme/mime_elements';
import { Link } from '@reach/router';
import '@missionme/mime_elements/dist/public/Css/GlobalSleep.css';
import '@missionme/mime_elements/dist/public/Css/GlobalColors.css';
import '@missionme/mime_elements/dist/public/Css/BootstrapUtils.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from 'views/Footer/Footer';
import { HeaderSection, THeaderSectionProps } from './HeaderSection/HeaderSection';
import { CheckMarkSection, TCheckMarkSectionProps } from './CheckMarkSection/CheckMarkSection';

type TProps = {
  children?: ReactNode;
} & {
  HeaderSection?: FC<THeaderSectionProps>;
  CheckMarkSection?: FC<TCheckMarkSectionProps>;
};

const SleepBrandLink = () => (
  <NavbarBrand
    tag={() => (
      <NavLink className="navbar-sleep-brand p-0" tag={Link} to="#home">
        7Schläfer Home
      </NavLink>
    )}
    to="#home"
  >
    Logo
  </NavbarBrand>
);


export function InsuranceLandingPage({ children }: TProps) {
  return (
    <Style className="insuranceCooperationLandingPage">
      <Menu brandLink={<SleepBrandLink />} />
      {children}
      <Footer renderCta />
    </Style>
  );
}

InsuranceLandingPage.HeaderSection = HeaderSection;
InsuranceLandingPage.CheckMarkSection = CheckMarkSection;
