import React, { FC } from 'react';
import { Button, Paragraph, Headline } from 'View/Common';
import { useDispatch } from 'react-redux';
import Actions from 'Redux/Actions';

export type TErrorViewProps = {
  children?: React.ReactNode;
  className?: string;
};

export const ErrorView: FC<TErrorViewProps> = () => {

  const dispatch = useDispatch();

  const handleOnResetForm = () => {
    dispatch(Actions.clearHealthInsuranceUserData());
  };

  return (
    <div className="error-view" id="insurance-cooperation-error-view">
      <Headline level={2}>Huch - das tut uns Leid!</Headline>
      <Paragraph className="px-2 px-md-5 mb-4">
        Da ist irgendwas schief gelaufen mit deiner Registrierung. Bitte beginne von Vorne oder - wenn du unsicher bist,
        kontaktiere unseren Kunden-Support.
      </Paragraph>
      <Button color="pink" className="mt-3" onClick={handleOnResetForm}>
        Zurück zur Registrierung
      </Button>
    </div>
  );
};
