import { RouteComponentProps } from '@reach/router';
import { CoopImage1, CoopImage2, CoopImage3, PhoneDark, SuccessImage } from 'img/b2b';
import BigLogo from 'img/b2b/big-logo.svg';
import React, { FC } from 'react';
import { Button, FormField, Headline, Icon, Image, List, Paragraph } from 'View/Common';
import { ImageParagraph, ImageTextRow } from 'View/Component';
import { InsuranceContainer, InsuranceLandingPage, Section } from 'View/Container';

export const BoilerplateInsuranceCooperationPage: FC<RouteComponentProps> = () => {
  const isMinTablet = window.matchMedia('(min-width: 768px)').matches;
  return (
    <InsuranceLandingPage>
      <InsuranceLandingPage.HeaderSection brandLogo={<Image src={BigLogo} alt="(#Krankenkassenname#) Logo" />}>
        <Headline level={2}>
          Erholsamen Schlaf finden. Mit der {isMinTablet && <br />} (#Krankenkassenname#) zur richtigen Schlafstrategie.
        </Headline>
        <Paragraph>Starte jetzt den Kurs (#Kursname#) und erlebe begleitend die gesamte Einschlaf-Welt der 7Schläfer App.</Paragraph>
        <Button>Jetzt starten</Button>
      </InsuranceLandingPage.HeaderSection>
      <InsuranceLandingPage.CheckMarkSection image={<Image src={PhoneDark} />}>
        <Headline level={2}>Entdecke, wie guter Schlaf dein Leben verändert.</Headline>
        <Paragraph>
          7Schläfer bietet Unterstützung beim Ein-, Aus- und Durchschlafen. Die App basiert auf den neuesten Erkenntnissen der Wissenschaft.
          Versicherte der (#Krankenkassenname#) erwartet im 7-wöchigen Präventionskurs ein Programm, in dem Schlafforscher und Biologe Dr.
          Albrecht Vorster die Teilnehmenden zu Schlafexperten in eigener Sache macht. Du erfährst, wie du erholsamen Schlaf mit gesunden
          Gewohnheiten und wirksamen Routinen förderst. Die kurzen Einheiten sind einfach verständlich und du kannst die Tipps und Tricks
          sofort in deinem Alltag umsetzen.
        </Paragraph>
        <List
          icon={
            <Icon color={['var(--sleep-red-c500)', 'var(--sleep-red-c500)']} name="CheckmarkCircle" size={{ lg: 27, md: 27, sm: 27 }} />
          }
        >
          {[
            '7 Wochen Schlaf-Kurs zur Verbesserung deines Schlafes',
            'Begleitende Trainings und Quizze um das gelernte im Alltag zu verankern',
            'Kurze, einfach verständliche Einheiten für zuhause oder unterwegs - mit nur 10 Minuten am Tag zu besserem Schlaf',
          ]}
        </List>
      </InsuranceLandingPage.CheckMarkSection>
      <Section className="paragraph-section" verticalPadding="medium">
        <Headline level={2} className="text-center">
          Für Versicherte der (#Krankenkassenname#) ist der <br /> Präventionskurs kostenlos.
        </Headline>
        <ImageTextRow>
          <ImageParagraph dataCyName="paragraph-image-1" image={CoopImage1}>
            Registriere dich einfach unten auf dieser Website. Beachte: Du musst 100% des Kurses absolvieren und darfst dein
            Präventionsbudget in diesem Jahr noch nicht ausgeschöpft haben.
          </ImageParagraph>
          <ImageParagraph dataCyName="paragraph-image-2" image={CoopImage2}>
            Lade dir anschließend die 7Schläfer-App herunter und melde dich mit deiner E-Mail-Adresse an. Dein kostenloser Zugriff auf den
            Kurs (#Kursname#) gilt für 12 Monate.
          </ImageParagraph>
          <ImageParagraph dataCyName="paragraph-image-3" image={CoopImage3}>
            Absolviere den Kurs vollständig innerhalb von 6 Monaten und erhalte dein Teilnahmezertifikat als Nachweis für die
            (#Krankenkassenname#) - als Bonus bleibt dein App-Zugriff auf die gesamte 7Schläfer Schlaf-Welt für weitere 6 Monate bestehen.
          </ImageParagraph>
        </ImageTextRow>
      </Section>
      <Section className="form-section">
        <InsuranceContainer b2bClientKey="b4e318d1-c76d-4fde-8e1f-4aaa2146864a">
          <InsuranceContainer.FormView className="text-center">
            <Headline level={2}>
              Registrierung für Versicherte der <br /> (#Krankenkassenname#)
            </Headline>
            <Paragraph>
              Registriere dich hier mit deiner (#Krankenkassenname#)-Versichertennummer und schalte den Kurs (#Kursname#) frei. Bei Fragen
              wende dich gerne an{' '}
              <a className="mail-link" href="mailto:partner@7schlaefer.app">
                partner@7schlaefer.app
              </a>
              .
            </Paragraph>
            <InsuranceContainer.Form>
              {({ register }) => (
                <>
                  <FormField {...register('name')} />
                  <FormField {...register('email')} />
                  <FormField {...register('insuranceNumber')} />
                  <Paragraph className="form-insurance-number-subtext">
                    Deine (#Krankenkassenname#)-Versichertennummer findest du auf deiner Krankenkassenkarte.
                  </Paragraph>
                  <FormField {...register('dateOfBirth')} />
                  <FormField {...register('age')} />
                  <FormField {...register('gender')} />
                  <FormField {...register('healthPreconditions')} />
                  <FormField {...register('legalConditions')} />
                  <FormField {...register('dataTransfer')} />
                  <FormField {...register('noMoreCourses')} />
                  <FormField {...register('successfulCourse')} />
                </>
              )}
            </InsuranceContainer.Form>
          </InsuranceContainer.FormView>
          <InsuranceContainer.OtpView />
          <InsuranceContainer.SuccessView>
            <Image src={SuccessImage} alt="Besser schlafen Kurs" />
          </InsuranceContainer.SuccessView>
        </InsuranceContainer>
      </Section>
    </InsuranceLandingPage>
  );
};
