import React, { ReactNode } from 'react';
import Style from './HeaderSection.style';
import { FunctionComponent } from 'react';
import { BgStars } from 'View/Component';
import { Image } from 'View/Common';
import logoBackground from 'img/CoopTemplate/img-background-kk-logo.svg';
import LunaSleeping from 'img/luna-sleeping.svg';

export type THeaderSectionProps = {
  children?: ReactNode,
  brandLogo?: ReactNode
}

export const HeaderSection: FunctionComponent<THeaderSectionProps> = ({ children, brandLogo }) => {
  return (
    <Style>
      <BgStars>
        <Image className="header-image" src={LunaSleeping} alt="Schlafen" />
        { children }
      </BgStars>
      {brandLogo && (
        <div className="brand-logo-wrapper">
          <Image className="logo-background" src={logoBackground} alt="Schlafen" />
          {brandLogo}
        </div>
      )}
    </Style>
  );
};
