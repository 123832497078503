import { FormikErrors, FormikHandlers, FormikValues, useFormik } from 'formik';
import React, { FC, ReactNode, useCallback, useMemo, FormEvent, useContext } from 'react';
import { insuranceFormRequiredFields } from 'utils/Constants/InsuranceFormRequiredFields';
import ErrorFocus from 'utils/Formik_Error_Focus';
import { isValidEmail, isValidInsuranceNumber } from 'utils/validations';
import { Button, TFormFieldProps } from 'View/Common';
import { HealthPreConditionsLabel, LegalConditionLabel } from 'View/Component';
import { useDispatch, useSelector } from 'react-redux';
import { toIsoDate } from 'utils';
import { InsuranceContainerContext } from 'View/Container/InsuranceContainer/InsuranceContainerContext';
import Actions from 'Redux/Actions';
import { TRegisterWithInsuranceNumberRequestPayload } from 'Redux/Reducers/InsuranceCooperation';
import { isInsuranceCooperationLoading } from 'Redux/Queries';
import { INSURANCE_CONTAINER_VIEW } from 'View/Container';

interface IRegister {
  handleChange: FormikHandlers['handleChange'];
  values: FormikValues;
  errors: FormikErrors<FormikValues>;
}

interface IFormChildrenProps {
  register: (name: string) => TFormFieldProps;
  values: FormikValues;
  errors: FormikErrors<FormikValues>;
}

export type TFormProps = {
  className?: string;
  children: ({ register, values, errors }: IFormChildrenProps) => ReactNode;
  defaultValues?: Record<string, unknown>;
  additionalFields?: string[];
};

export const Form: FC<TFormProps> = ({ defaultValues = {}, additionalFields = [], className = '', children }) => {

  const dispatch = useDispatch();
  const isLoading = useSelector(isInsuranceCooperationLoading);

  const _register = useCallback(
    ({ handleChange, values, errors }: IRegister) =>
      (name: string) => {
        const fieldProps: Record<string, any> = {
          type: 'input',
          label: undefined as string | ReactNode,
        };

        switch (name) {
          case 'name': {
            fieldProps.label = 'Name';
            fieldProps.placeholder = 'Vor- und Nachname*';
            fieldProps.required = true;
            break;
          }
          case 'email': {
            fieldProps.label = 'E-Mail';
            fieldProps.placeholder = 'E-Mail*';
            fieldProps.required = true;
            break;
          }
          case 'insuranceNumber': {
            fieldProps.label = 'Versichertennummer';
            fieldProps.placeholder = 'Versichertennummer*';
            fieldProps.required = true;
            break;
          }
          case 'dateOfBirth': {
            fieldProps.label = 'Geburtsdatum';
            fieldProps.placeholder = 'Geburtsdatum* (TT.MM.JJJJ)';
            fieldProps.required = true;
            break;
          }
          case 'age': {
            fieldProps.label = 'Geburtsdatum';
            fieldProps.placeholder = 'Alter';
            break;
          }
          case 'healthCheck': {
            fieldProps.type = 'checkbox';
            fieldProps.label = 'Health Checkbox';
            break;
          }
          case 'termsCheck': {
            fieldProps.type = 'checkbox';
            fieldProps.label = 'Terms Checkbox';
            break;
          }
          case 'precondition': {
            fieldProps.type = 'checkbox';
            fieldProps.label = 'PreConditions Checkbox';
            break;
          }
          case 'gender': {
            fieldProps.label = 'Geschlecht';
            fieldProps.placeholder = 'Geschlecht';
            fieldProps.type = 'select';
            fieldProps.options = [
              { key: 'FEMALE', value: 'FEMALE', label: 'weiblich' },
              { key: 'MALE', value: 'MALE', label: 'männlich' },
              { key: 'DIVERS', value: 'DIVERS', label: 'divers' }
            ];
            break;
          }
          case 'healthPreconditions': {
            fieldProps.type = 'toggle';
            fieldProps.required = true;
            fieldProps.label = <HealthPreConditionsLabel />;
            break;
          }
          case 'legalConditions': {
            fieldProps.type = 'toggle';
            fieldProps.required = true;
            fieldProps.label = <LegalConditionLabel />;
            break;
          }
          case 'dataTransfer': {
            fieldProps.type = 'toggle';
            fieldProps.required = true;
            fieldProps.label =
              'Ich bin damit einverstanden, dass meine persönlichen Daten, die ich hier im Formular angegeben habe, sowie mein Kursfortschritt an die (#Krankenkassenname#) übermittelt werden.';
            break;
          }
          case 'noMoreCourses': {
            fieldProps.type = 'toggle';
            fieldProps.required = true;
            fieldProps.label =
              'Hiermit bestätige ich, dass ich in diesem Kalenderjahr noch nicht an zwei Präventionskursen teilgenommen habe.';
            break;
          }
          case 'successfulCourse': {
            fieldProps.type = 'toggle';
            fieldProps.required = true;
            fieldProps.label =
              'Mir ist bewusst, dass die Übernahme der Kosten durch meine Krankenkasse (#Krankenkassenname#) auf einem erfolgreichen Kursabschluss beruht.';
            break;
          }
        }

        return {
          id: name,
          name,
          value: values[name],
          errorMessage: errors?.[name],
          onChange: handleChange,
          ...fieldProps,
        };
      },
    [],
  );

  const _fields = useMemo(() => {
    return [
      ...additionalFields,
      'name',
      'email',
      'insuranceNumber',
      'dateOfBirth',
      'healthPreconditions',
      'legalConditions',
      'dataTransfer',
      'noMoreCourses',
      'successfulCourse',
      'gender',
    ];
  }, []);
  const { b2bClientKey } = useContext(InsuranceContainerContext);

  const validation = (values: FormikValues): FormikErrors<FormikValues | undefined> => {
    const errors: Record<string, string> = {};

    _fields.forEach((field) => {
      if (!values[field]) {
        errors[field] = (insuranceFormRequiredFields[field] || 'Feld wird benötigt') as string;
      }
      switch (field) {
        case 'email': {
          if (!isValidEmail(values[field])) {
            errors[field] = 'E-Mail ist ungültig';
          }
          break;
        }
        case 'insuranceNumber': {
          if (!isValidInsuranceNumber(values[field])) {
            errors[field] = 'Versichertennummer ist ungültig';
          }
          break;
        }
        case 'dateOfBirth': {
          if (!values[field]) {
            errors[field] = 'Geburtsdatum wird benötigt.';
          }
          break;
        }
        case 'healthPreconditions': {
          if (!values[field]) {
            errors[field] = 'Bitte akzeptiere die Gesundheitsvoraussetzungen.';
          }
          break;
        }
        case 'legalConditions': {
          if (!values[field]) {
            errors[field] = 'Bitte akzeptiere die AGB und Hinweise zum Datenschutz.';
          }
          break;
        }
        case 'dataTransfer': {
          if (!values[field]) {
            errors[field] = 'Bitte akzeptiere die Datenweitergabe.';
          }
          break;
        }
        case 'noMoreCourses': {
          if (!values[field]) {
            errors[field] = 'Bitte bestätige dein Präventionsbudget.';
          }
          break;
        }
        case 'successfulCourse': {
          if (!values[field]) {
            errors[field] = 'Bitte bestätige, dies zur Kenntniss genommen zu haben.';
          }
          break;
        }
        case 'gender': {
          if (!values[field]) {
            errors[field] = 'Geschlecht wird benötigt.';
          }
          break;
        }
      }
    });

    if (Object.values(errors).some((item) => item?.length)) {
      return errors;
    }

    return undefined;
  };

  const { values, errors, isSubmitting, isValidating, handleChange, handleSubmit } = useFormik({
    initialValues: defaultValues,
    onSubmit: (values) => {
      console.log(JSON.stringify(values, null, 2));
    },
    validateOnBlur: false,
    validateOnChange: false,
    validate: validation,
  });

  const handleOnSubmitForm = useCallback((evt: FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    evt.stopPropagation();

    const _values = { ...values };
    Object.keys(_values).forEach((key) => {
      const value = _values[key];
      if(!value && defaultValues[key]) {
        _values[key] = defaultValues[key];
      }
    });

    const { name = '', email = '', insuranceNumber = '', ...userMetaData } = _values;
    const _userMetaData = { ...userMetaData };

    if(_values.dateOfBirth) {
      _userMetaData.dateOfBirth = toIsoDate(_values.dateOfBirth as string);
    }

    dispatch(Actions.setInsuranceProvider({ provider: b2bClientKey }));
    dispatch(Actions.registerWithInsuranceNumberRequest({ user: { name, email, insuranceNumber }, userMetaData: _userMetaData } as TRegisterWithInsuranceNumberRequestPayload));
  }, [values]);

  const isButtonDisabled = useCallback(() => {
    return isLoading || Object.values(errors).some((item) => item?.length) || !!validation(values);
  }, [isLoading, errors, values]);


  return (
    <form className={`insurance-form ${className}`} onSubmit={handleOnSubmitForm}>
      {children({
        register: _register({ values, errors, handleChange }),
        values,
        errors,
      })}
      <div className="form-button">
        <Button disabled={isButtonDisabled()} htmlType="submit">Registrieren</Button>
      </div>
      <ErrorFocus isSubmitting={isSubmitting} isValidating={isValidating} errors={errors}/>
    </form>
  );
};
