import styled from 'styled-components';
import { Section } from 'View/Container/index';
import { asRem } from 'utils/asRem';
import { breakpoints } from 'View/Theme';

export default styled(Section).attrs({
  className: 'checkmark-section',
  backgroundColor: 'c850',
})`
  &.checkmark-section {
    padding-bottom: 0;
    overflow: hidden;
  }

  ul {
    margin-left: ${asRem(0)};
    padding-bottom: ${asRem(32)};
  }

  i {
    align-self: flex-start !important;
  }

  .mime-elements-image {
    position: relative;
    margin-bottom: ${asRem(-80)};
    width: 70%;
    margin-top: ${asRem(-30)};
    right: -15%;
    align-self: center;

    @media (min-width: ${breakpoints.md}) {
      position: absolute;
      right: ${asRem(10)};
      width: 90%;
      min-width: ${asRem(100)};
      max-width: ${asRem(340)};
      margin-right: ${asRem(20)};
      bottom: ${asRem(170)};
    }

    @media (min-width: ${breakpoints.lg}) {
      max-width: ${asRem(360)};
      bottom: ${asRem(-15)};
    }
  }
`;
